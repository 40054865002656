<div class="card" style="padding: 20px; height: 100vh;
overflow: auto;">
  <div class="p-fluid row">
    <div class="col-12 col-md-6">
      <div class="col-12 mb-4" style=" width:100%; text-align: center;">
        <img src="{{banner}}" style="width: 70%;">
      </div>
      <div class="col-12 p-4" style="text-align: center;">
        <h4 class="mb-0"> {{informativo_cadastro}}</h4>
        <p class="mb-0">{{descricao}}</p>
      </div>
      <div class="col-12 p-4 links">
        <div *ngIf="linkFacebook" class="col-3 p-2" style="padding: 30px; ">
          <a style="cursor: pointer;" href="{{linkFacebook}}" target="_blank">
            <i class="fa fa-facebook-square" style="font-size: 40px; color: #0778e9;"></i>
          </a>
        </div>
        <div *ngIf="linkInsta" class="col-3 p-2" style="padding: 30px; ">
          <a style="cursor: pointer;" href="{{linkInsta}}" target="_blank">

            <i class="fa fa-instagram" style="font-size: 40px; color: #fd0070;"></i>
          </a>
        </div>
        <div *ngIf="linkTwitter" class="col-3 p-2" style="padding: 30px; ">
          <a style="cursor: pointer;" href="{{linkTwitter}}" target="_blank">
            <i class="fa fa-twitter-square" style="font-size: 40px; color: #317ac1;"></i>
          </a>
        </div>
        <div *ngIf="linkWhats" class="col-3 p-2" style="padding: 30px; ">
          <a style="cursor: pointer;" href="{{linkWhats}}" target="_blank">
            <i class="fa fa-whatsapp" style="font-size: 40px; color: #0dc143;"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <form [formGroup]="form" class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 mb-4" formGroupName="pessoa">
          <label>Tipo de pessoa<small [ngStyle]="{'color': 'red'}"> *</small> </label>
          <p-dropdown [options]="dropDownTipo" class="ml-2 p-inputtext-sm" formControlName="tipo"></p-dropdown>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12 mb-4" formGroupName="pessoa">
          <label for="nome">Nome <small [ngStyle]="{'color': 'red'}"> *</small> </label>
          <input id="nome" type="text" pInputText formControlName="nome">
        </div>

        <div *ngIf="form.get('pessoa.tipo').value == 'FISICA'" class="col-sm-12 col-md-12 col-lg-12 mb-4" formGroupName="pessoa_fisica">
          <label>CPF<small [ngStyle]="{'color': 'red'}"> *</small> </label>
          <p-inputMask placeholder="xxx.xxx.xxx-xx" (onBlur)="validarCPF(form.get('pessoa_fisica.cpf_cnpj').value)"
            mask="999.999.999-99" formControlName="cpf_cnpj">
          </p-inputMask>
        </div>

        <div *ngIf="form.get('pessoa.tipo').value == 'JURIDICA'" class="col-sm-12 col-md-12 col-lg-12 mb-4" formGroupName="pessoa_fisica">
          <label>CNPJ<small [ngStyle]="{'color': 'red'}"> *</small> </label>
          <p-inputMask placeholder="xx.xxx.xxx/xxxx-xx"
            mask="99.999.999/9999-99" formControlName="cpf_cnpj">
          </p-inputMask>
        </div>
        <div *ngIf="form.get('pessoa.tipo').value == 'JURIDICA'" class="p-field-checkbox col-sm-12 col-md-12 col-lg-12 mb-4" formGroupName="pessoa">
          <p-checkbox formControlName="isento_ie" binary="true"></p-checkbox>
          <label style="margin-left: 10px;">Isento de Inscrição Estadual</label>
        </div>
        <div *ngIf="form.get('pessoa.tipo').value == 'JURIDICA' && form.get('pessoa.isento_ie').value == false" class="col-sm-12 col-md-12 col-lg-12 mb-4" formGroupName="pessoa">
          <label>Inscrição estadual<small [ngStyle]="{'color': 'red'}"> *</small> </label>
          <input id="ie" type="text" pInputText formControlName="ie">
        </div>

        <div *ngIf="form.get('pessoa.tipo').value == 'FISICA'" class="col-sm-12 col-md-12 col-lg-12 mb-4" formGroupName="pessoa_fisica">
          <label>Data de nascimento <small [ngStyle]="{'color': 'red'}"> *</small> </label>
          <input id="nascimento" type="date" pInputText formControlName="nascimento">
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12 mb-4" formGroupName="pessoa_contato">
          <label>Whatsapp <small [ngStyle]="{'color': 'red'}"> *</small> </label>
          <p-inputMask placeholder="(99) 99999-9999" mask="+55 (99) 9 9999-9999" formControlName="celular">
          </p-inputMask>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12 mb-4" formGroupName="pessoa_contato">
          <label>E-mail <small [ngStyle]="{'color': 'red'}"> *</small> </label>
          <input pInputText formControlName="email" (blur)="validarEmail(form.get('pessoa_contato.email').value)">
        </div>
        <div class="d-flex col-sm-12 col-md-12 col-lg-12 mb-4">
          <div class="col-sm-11 col-md-11 col-lg-11" formGroupName="pessoa_endereco">
            <label>CEP <small [ngStyle]="{'color': 'red'}"> *</small> </label>
            <input pInputText formControlName="cep" (focusout)="consultaCEP(form.get('pessoa_endereco.cep').value)">
          </div>
          <div class="col-sm-1 col-md-1 col-lg-1" *ngIf="loadingCep" style="padding: 25px;">
            <p-progressSpinner [style]="{width: '40px', height: '40px', padding: '10px'}" styleClass="custom-spinner"
              strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
          </div>
        </div>


        <div class="col-sm-12 col-md-12 col-lg-12 mb-4" formGroupName="pessoa_endereco">
          <label>Logradouro<small [ngStyle]="{'color': 'red'}"> *</small> </label>
          <input pInputText formControlName="logradouro">
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12 mb-4" formGroupName="pessoa_endereco">
          <label>Numero</label>
          <input pInputText formControlName="numero">
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12 mb-4" formGroupName="pessoa_endereco">
          <label>Bairro <small [ngStyle]="{'color': 'red'}"> *</small> </label>
          <input pInputText formControlName="bairro">
        </div>

        <div class="col-sm-12 col-md-12 col-lg-6 mb-4" formGroupName="pessoa_endereco">
          <label>Município <small [ngStyle]="{'color': 'red'}"> *</small> </label>

          <input pInputText formControlName="cidade">
        </div>

        <div class="col-sm-12 col-md-12 col-lg-6 mb-4" formGroupName="pessoa_endereco">
          <label>UF <small [ngStyle]="{'color': 'red'}"> *</small> </label>
          <input pInputText formControlName="uf">
        </div>


      </form>

      <div class="p-field-checkbox p-mt-4 col-sm-12 col-md-12 col-lg-12 mb-4">
        <p-checkbox [(ngModel)]="termo_aceite" binary="true"></p-checkbox>
        <label style="margin-left: 10px;">Eu estou de acordo com os<a href="{{termo_uso}}" target="_blank"> Termos de
            uso </a></label>
      </div>

      <p-message *ngIf="showMensagemSalvo && loading == false" severity="success" text="Cadastro salvo com sucesso"
        styleClass="p-mr-2 mb-4">
      </p-message>

      <div class="col-sm-1 col-md-1 col-lg-1" *ngIf="loading" style="text-align: center;">
        <p-progressSpinner [style]="{width: '40px', height: '40px', padding: '10px'}" styleClass="custom-spinner"
          strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
      </div>

      <div *ngIf="loading == false" class="col-sm-12 col-md-12 col-lg-12 mb-4">
        <p-button [disabled]="!termo_aceite || form.invalid || emailValido == false || cpfValido == false"
          label="Salvar" icon="pi pi-save" (click)="salvar()">
        </p-button>
      </div>

    </div>

  </div>
</div>
