import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CampoPersonalizadoServiceService } from 'src/app/core/services/campo-personalizado-service.service';
import { InsumoService } from 'src/app/core/services/insumo.service';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.scss']
})
export class ProdutosComponent {
  dataSource = [];

  modal = false;

  form: FormGroup;

  campos_personalizados: any[] = []

  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private campoPersonalizadoService: CampoPersonalizadoServiceService,
    private insumoService: InsumoService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: [null],
      nome: [null, Validators.required],
      ativo: [null],
    })


    this.index();
  }

  showModal(id = null) {
    this.loading = true;

    this.modal = true;

    this.form.reset();

    this.form.get('ativo').setValue(true);

    this.campos_personalizados = [];
    if(id == null){
      setTimeout(() => {
        let query = 'localidade=PRODUTO';
        this.campoPersonalizadoService.index(null, query).subscribe(json => {
          json.map(item => {
            if(item.ativo == true){
    this.campos_personalizados.push(item)
            }
          })
          this.loading = false
        })
      }, 1000);
    } else {
      this.insumoService.show(id).subscribe(json => {
        this.form.patchValue(json)
      })
      setTimeout(() => {
        this.insumoService.getCamposPersonalizados(id).subscribe(json => {
          json.map(item => {
            this.campos_personalizados.push(item)
          })
          this.loading = false
        })
      }, 1000);
    }

    
  }

  index() {
    this.dataSource = [];
    this.insumoService.index().subscribe(json => {
      this.dataSource = json
    })
   
  }

  save(){
    let form = this.form.value

    let data = {
      insumo:{
        id: form.id,
        ativo: form.ativo,
        nome: form.nome,
      },
      campos_personalizados: this.campos_personalizados
      
    }



    this.insumoService.save(data, this.form.get('id').value).subscribe(json => {
      this.modal = false;
      this.index();
    })
  }

}
