import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GatilhoService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'gatilho');
  }

  listByDepartamentoUsuario(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/by/usuario`);

  }

}
