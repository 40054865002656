import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule, NgbDropdownModule, NgbAccordionModule, NgbModalModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { LightboxModule } from 'ngx-lightbox';

import { TabsModule } from './tabs/tabs.module';

import { ChatRoutingModule } from './chat-routing.module';

import {DatePipe} from '@angular/common';

// Emoji Picker
import { PickerModule } from '@ctrl/ngx-emoji-mart';

// Simplebar
import { SimplebarAngularModule } from 'simplebar-angular';

import { IndexComponent } from './index/index.component';
import { TranslateModule } from '@ngx-translate/core';

import {DialogModule} from 'primeng/dialog';

import {ButtonModule} from 'primeng/button';

import {ProgressSpinnerModule} from 'primeng/progressspinner';


import { RespostaMensagemComponent } from './index/resposta-mensagem/resposta-mensagem.component';
import { CdTimerModule } from 'angular-cd-timer';
import { CompartilharMensagemComponent } from './index/compartilhar-mensagem/compartilhar-mensagem.component';

import {AutoCompleteModule} from 'primeng/autocomplete';

import {TableModule} from 'primeng/table';

import {GMapModule} from 'primeng/gmap';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import {BadgeModule} from 'primeng/badge';
import { GroupsComponent } from './groups/groups.component';
import { ImageModule } from 'primeng/image';
import {TabViewModule} from 'primeng/tabview';
import {ToolbarModule} from 'primeng/toolbar';
import { CadastrosModule } from '../cadastros/cadastros.module';

import {PaginatorModule} from 'primeng/paginator';

import {SplitButtonModule} from 'primeng/splitbutton';

import {MultiSelectModule} from 'primeng/multiselect';
import { InfoChatComponent } from './info-chat/info-chat.component';
import { SettingsPageModule } from '../settings/settings-page.module';
import { NotepadComponent } from './notepad/notepad.component';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { KanbanModule } from '../kanban/kanban.module';
import {PanelModule} from 'primeng/panel';
import {CheckboxModule} from 'primeng/checkbox';
import { InstanciaWhatsappModule } from '../instancia-whatsapp/instancia-whatsapp.module';
import { ReportsModule } from '../reports/reports.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { MessagesModule } from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { CoreModule } from '../core/core.module';
import {PickListModule} from 'primeng/picklist';
import {OrderListModule} from 'primeng/orderlist';
import { InputMaskModule } from 'primeng/inputmask';
import { AgendaModule } from '../agenda/agenda.module';
import { CalendarModule } from 'primeng/calendar';
import { TagModule } from 'primeng/tag';
import {AccordionModule} from 'primeng/accordion';
import {TimelineModule} from 'primeng/timeline';

// import {PrimeIcons} from 'primeng/api';


@NgModule({
  declarations: [IndexComponent, RespostaMensagemComponent, CompartilharMensagemComponent, GroupsComponent, InfoChatComponent, NotepadComponent],
  imports: [
    PerfectScrollbarModule,
    LightboxModule,
    NgbAccordionModule,
    NgbModalModule,
    NgbCollapseModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ChatRoutingModule,
    TabsModule,
    NgbTooltipModule,
    NgbDropdownModule,
    TranslateModule,
    SimplebarAngularModule,
    PickerModule,
    DialogModule,
    ButtonModule,
    CdTimerModule,
    ProgressSpinnerModule,
    AutoCompleteModule,
    TableModule,
    GMapModule,
    ConfirmDialogModule,
    DropdownModule,
    BadgeModule,
    ImageModule,
    TabViewModule,
    ToolbarModule,
    CadastrosModule,
    PaginatorModule,
    SplitButtonModule,
    MultiSelectModule,
    SettingsPageModule,
    OverlayPanelModule,
    InputTextareaModule,
    KanbanModule,
    PanelModule,
    CheckboxModule,
    InstanciaWhatsappModule,
    ReportsModule,
    DashboardModule,
    MessagesModule,
    MessageModule,
    CoreModule,
    PickListModule,
    OrderListModule,
    InputMaskModule,
    AgendaModule,
    CalendarModule,
    TagModule,
    AccordionModule,
    TimelineModule

    // PrimeIcons
  ],
  providers: [
    DatePipe
  ],
  exports: []
})
export class ChatModule { }
