<p-tabView>
  <p-tabPanel header="Origem do lead">
    <app-origem-lead></app-origem-lead>
  </p-tabPanel>
  <p-tabPanel header="Tipo de interesse do ticket">
    <app-interesse-ticket></app-interesse-ticket>
  </p-tabPanel>
  <p-tabPanel header="Qualificação do ticket">
    <app-qualificacao-lead></app-qualificacao-lead>
  </p-tabPanel>
</p-tabView>
