import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ChatService } from 'src/app/core/services/chat.service';

@Component({
  selector: 'app-notepad',
  templateUrl: './notepad.component.html',
  styleUrls: ['./notepad.component.scss']
})
export class NotepadComponent {
  @ViewChild('op') op: OverlayPanel;

  @Input() chat;
  notepadList = [];
  notepad;
  loading = false;

  constructor(
    private chatService: ChatService
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.notepadList = [];
    if (this.chat) {
      this.chatService.show(this.chat.id).subscribe(json => {
        let notes = json?.anotacoes;
        try {
          if (notes.length > 0) {
            notes.forEach(note => {
              this.notepadList.push({ id: note.id, notepad: note.anotacao })
            });
          }
        } catch (error) {

        }
      })
    }
  }

  addNotepad() {
    this.loading = true;
    this.op.hide();

    let body = {
      chatId: this.chat.id,
      note: this.notepad
    }

    this.chatService.addNotepad(body).subscribe(json => {
      this.loading = false;
      this.notepadList.push({ id: json.id, notepad: json.anotacao });
      this.notepad = null;
    })
  }

  removeNotepad(note) {
    this.loading = true;
    this.chatService.deleteNotepad(note.id).subscribe(json => {
      this.chatService.show(this.chat.id).subscribe(json => {
        this.notepadList = [];
        let notes = json?.anotacoes;
        try {
          if (notes.length > 0) {
            notes.forEach(note => {
              this.notepadList.push({ id: note.id, notepad: note.anotacao })
            });
          }
        } catch (error) {

        }

        this.loading = false;
      })
    })
  }

}
