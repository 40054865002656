import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAutoLink]'
})
export class AutoLinkDirective {
  @Input() appAutoLink!: string;  // Input para receber o conteúdo dinâmico


  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.convertUrlsToLinks(this.appAutoLink || this.el.nativeElement.innerHTML);
  }

  private convertUrlsToLinks(text: string) {
    // Expressão regular para detectar URLs que começam com http ou https
    const urlRegex = /(http[s]?:\/\/[^\s]+)/g;

    // Substituir URLs no texto por links
    const newText = text.replace(urlRegex, (url: string) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });

    // Usar Renderer2 para definir o HTML
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', newText);
  }

}
