import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { FunilAtendimentoService } from 'src/app/core/services/funil-atendimento.service';



@Component({
  selector: 'app-funnel',
  templateUrl: './funnel.component.html',
  styleUrls: ['./funnel.component.scss']
})
export class FunnelComponent {
  dataSource = [];
  form: FormGroup;
  formEtapas: FormGroup;
  etapasList = [{
    id: null,
    nome: null,
    ativo: true,
    ordem: 1
  }];
  modal = false;
  loading = false;

  constructor(private formBuilder: FormBuilder, private funilAtendimentoService:FunilAtendimentoService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: [null],
      nome: [null],
      ativo: [null]
    })

    this.formEtapas = this.formBuilder.group({
      nome: [null],
      ordem: [null],
      ativo: [null]
    });

    this.index();
  }

  index() {
    this.loading = true;
    this.funilAtendimentoService.index().subscribe(json => {
      this.loading = false;
      this.dataSource = json;
    })
  }

  addEtapa() {
    this.etapasList.push({
      id: null,
      nome: null,
      ativo: true,
      ordem: null
    })
  }

  showForm(id = null) {
    this.form.reset();
    this.formEtapas.reset();

    this.form.get('ativo').setValue(true);
    this.formEtapas.get('ativo').setValue(true);

    this.modal = true;

    if (id != null) {
      this.loading = true;
      this.form.disable();
      
      this.funilAtendimentoService.show(id).subscribe(json => {
        this.loading = false;        
        this.form.enable();
        this.form.patchValue(json);
        this.etapasList = json.etapas;
      })
    }
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.dataSource);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "departamentos");
    });
  }


  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  save() { 
    let body:any = this.form.value;
    body = {...body, etapas: this.etapasList}

    this.funilAtendimentoService.save(body, this.form.get('id').value).subscribe(json => {
      // this.modal = false;
      this.index();
    })
  }
}
