import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class OportunidadeEtapaService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'oportunidade-etapa');
  }

  autocomplete(nome = null): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/autocomplete/${nome}`);
  }

  listAll(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/all`);
  }


  filterOportunity(id = null, tags = null, etapas = null, start = null, end = null, grid = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}?`;


    if (id !== null) {
      url += '&cliente=' + id;
    }

    if (tags !== null) {
      url += '&tags=' + tags;
    }

    if (etapas !== null) {
      url += '&etapas=' + etapas;
    }

    if (start !== null) {
      url += '&startDate=' + start;
    }


    if (end !== null) {
      url += '&endDate=' + end;
    }

    if (grid !== null) {
      url += '&grid=' + grid;
    }

    return this.http.get(url);
  }
}
