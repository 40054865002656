import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatInternoService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'chat-interno');
  }

  listByUsuario(usuarioId = null): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list-by-usuario/${usuarioId}`);
  }

  getMensagens(chatId: number): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/mensagens/${chatId}`;
    return this.http.get(url);
  }

  sendMensagem(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/send/mensagem`, body);
  }

  closeChat(chatId: number): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/close/chat/${chatId}`, null);
  }


  sendFile(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/send/file`, body);
  }

  sendAudio(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/send/audio`, body);
  }

  addChat(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/add/chat`, body);
  }

  forward_message_by_whatsapp(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/forward-message/whatsapp`, body);
  }
}
