import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { LinkService } from 'src/app/core/services/link.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent {

  form: FormGroup;
  modal = false;
  dataSource = [];
  urlUpload: string = environment.urlMultiUpload;
  filesLink: any[] = [];
  filesTermo: any[] = [];
  linkFull;

  constructor(
    private formBuilder: FormBuilder,
    private linkService: LinkService
  ) {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: [null],
      nome: [null, Validators.required],
      descricao: [null],
      link_instagram: [null],
      link_facebook: [null],
      link_twitter: [null],
      link_whatsapp: [null],
      token: [null],
      arquivo_id: [null],
      termo_id: [null],
    });

    this.index();
  }

  index() {
    this.linkService.index().subscribe(json => {
      this.dataSource = json;
    })
  }

  onBasicUploadLink(event) {
    this.filesLink = []
    event.originalEvent.body.forEach(x => {
      this.filesLink.push({ arquivo_id: x.id, nome: x.original_name });
      this.form.get('arquivo_id').setValue(x.id);
    });
  }

  onBasicUploadTermo(event) {
    this.filesTermo = []
    event.originalEvent.body.forEach(x => {
      this.filesTermo.push({ arquivo_id: x.id, nome: x.original_name });
      this.form.get('termo_id').setValue(x.id);
    });
  }

  showForm(id = null) {
    this.filesLink = [];
    this.form.reset();
    this.modal = true;
    if (id != null) {
      this.form.disable();
      this.linkService.show(id).subscribe(item => {
        this.form.enable();
        this.form.patchValue(item);

        this.linkFull = `${environment.urlAplicacao}/auto-cadastro/${item.token}`;

        this.form.get('token').disable();

        if (item.arquivo != null) {
          this.filesLink.push({ arquivo_id: item.arquivo.id, nome: item.arquivo.original_name })
        }
        if (item.termo != null) {
          this.filesTermo.push({ arquivo_id: item.termo.id, nome: item.termo.original_name })
        }
      })
    }
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.dataSource);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "links");
    });
  }


  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  generateUniqueId() {
    return Date.now() + Math.floor(Math.random() * 1e9) + '-' + localStorage.getItem('subdominio');
  }

  save() {
    let body = this.form.value;
    if (body.id == null) {
      body['token'] = this.generateUniqueId();
    }
    this.linkService.save(body, body.id).subscribe(json => {
      this.modal = false;
      this.index();
    })
  }


}
