import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CampoPersonalizadoServiceService } from 'src/app/core/services/campo-personalizado-service.service';
import { DepartamentoService } from 'src/app/core/services/departamento.service';
import { DireitoService } from 'src/app/core/services/direito.service';

@Component({
  selector: 'app-campo-personalizado-pessoa',
  templateUrl: './campo-personalizado-pessoa.component.html',
  styleUrls: ['./campo-personalizado-pessoa.component.scss']
})
export class CampoPersonalizadoPessoaComponent {

  dataSource = [];

  modal = false;

  form: FormGroup;

  formOpcao: FormGroup;

  dropdownTipo = [
    { value: 'STRING', label: 'Texto' },
    { value: 'NUMBER', label: 'Numerico' },
    { value: 'CURRENCY', label: 'Moeda' },
    { value: 'DROPDOWN', label: 'Lista' },
    { value: 'DATE', label: 'Data' },
    // { value: 'DATETIME', label: 'Data e hora' },
  ]

  opcoesCampo = [];

  dropdownDepartamento = [];

  constructor(
    private formBuilder: FormBuilder,
    private campoPersonalizadoService: CampoPersonalizadoServiceService,
    private departamentoService: DepartamentoService,
    public direitoService: DireitoService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: [null],
      campo: [null, Validators.required],
      tipo: [null, Validators.required],
      ativo: [null],
      localidade: [null],
      opcoes: [null],
      departamento_id: [null],
      editavel: [null],
      filtro_rapido: [null],
    })

    this.formOpcao = this.formBuilder.group({
      id: [null],
      label: [null, Validators.required],
      value: [null, Validators.required],
      ativo: [null]
    });

    this.index();
    this.listDepartamentos();
  }

  showModal(id = null) {
    this.modal = true;

    this.form.reset();

    this.form.get('ativo').setValue(true);
    this.form.get('localidade').setValue('PESSOA');
    this.form.get('editavel').setValue(true);

    this.opcoesCampo = [];

    if (id) {
      this.campoPersonalizadoService.show(id).subscribe(json => {
        this.form.patchValue(json);

        this.opcoesCampo = json.opcoes;
      })
    }
  }

  index() {
    this.dataSource = [];
    let query = 'localidade=PESSOA';
    this.campoPersonalizadoService.index(null, query).subscribe(json => {
      json.map(item => {
        this.dataSource.push({
          id: item.id,
          campo: item.campo,
          ativo: item.ativo,
          departamento: item?.departamento?.nome || 'Todos'
        })
      })
    })
  }


  listDepartamentos() {
    this.departamentoService.index().subscribe(json => {
      json.map(item => {
        this.dropdownDepartamento.push({value: item.id, label: item.nome});
      })
    })
  }



  save() {
   
    this.form.get('opcoes').setValue(this.opcoesCampo);

let form = this.form.value

let departamentos: any[] = []

form.departamento_id.map(item => {
  departamentos.push(item.value)
})

    let data = {
      id: form.id,
      campo: form.campo,
      tipo: form.tipo,
      ativo: form.ativo,
      localidade: form.localidade,
      opcoes: form.opcoes,
      departamento: departamentos,
      editavel: form.editavel,
      filtro_rapido: form.filtro_rapido,
    }

     

    this.campoPersonalizadoService.save(data, this.form.get('id').value).subscribe(json => {
      this.modal = false;
      this.index();
    })
  }

  addOpcao() {
    this.formOpcao.get('ativo').setValue(true);
    this.opcoesCampo.push(this.formOpcao.value);
    this.formOpcao.reset();
  }

}
