import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { OrigemLeadService } from 'src/app/core/services/origem-lead.service';

@Component({
  selector: 'app-origem-lead',
  templateUrl: './origem-lead.component.html',
  styleUrls: ['./origem-lead.component.scss']
})
export class OrigemLeadComponent {

  form: FormGroup;
  modal = false;
  dataSource = [];

  constructor(
    private formBuilder: FormBuilder,
    private origemLeadService: OrigemLeadService,
  ) {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: [null],
      descricao: [null, Validators.required],
      ativo: [null],
    });

    this.index();
  }


  index() {
    this.origemLeadService.index().subscribe(origem => {
      this.dataSource = origem;
    })
  }

  showForm(id = null) {
    this.form.reset();
    this.modal = true;
    this.form.get('ativo').setValue(true)
    if (id != null) {
      this.form.disable();
      this.origemLeadService.show(id).subscribe(tag => {
        this.form.enable();
        this.form.patchValue(tag);
      })
    }
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.dataSource);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "origem-lead");
    });
  }


  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  save() {
    let body = this.form.value;
    this.origemLeadService.save(body, body.id).subscribe(json => {
      this.modal = false;
      this.index();
    })
  }
}
