<perfect-scrollbar class="scroll" data-simplebar>
  <div class="card">
    <div class="card-body row">
      <div class="col-sm-8">
        <p-panel header="Criar Resposta Rápida">
          <form [formGroup]="form">
            <div class="p-fluid row">
              <div class="col-md-12 mb-12" style="padding-top: 10px;">

                <span class="p-float-label">
                  <p-dropdown appendTo="body" [filter]="true" [options]="dropdownDepartamento"
                    formControlName="departamento_id"></p-dropdown>
                  <label for="departamento">Departamento</label>
                </span>

              </div>
              <div class="col-md-12 mb-12" style="padding-top: 30px;">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    /
                  </span>
                  <span class="p-float-label">
                    <input type="text" formControlName="atalho" pInputText>
                    <label for="inputgroup">Nome do Atalho</label>
                  </span>
                </div>
              </div>

              <div class="col-md-12 mb-12" style="padding-top: 30px;">
                <span class="p-float-label">
                  <textarea [rows]="5" [cols]="115" pInputTextarea formControlName="texto"></textarea>
                  <label for="inputgroup">Texto que será inserido</label>
                </span>

              </div>

              <div class="col-md-6 mb-6" style="padding-top: 30px;">
                <div>
                  <button type="button" class="btn btn-success waves-effect waves-light me-1" (click)="save()"><i
                      class="fa fa-check-circle"></i> Salvar Resposta
                    Rápida</button>
                </div>
              </div>
              <div class="col-md-6 mb-6" style="padding-top: 30px;">
                <div>
                  <button type="button" (click)="ag.toggle($event)"
                    class="btn btn-primary waves-effect waves-light me-1"><i class="fa fa-info-circle"></i> Dicionário
                    de palavras chaves</button>

                  <p-overlayPanel #ag [showCloseIcon]="true" [style]="{width: '450px'}">
                    <ng-template pTemplate>
                      <p-table [value]="dicionario" responsiveLayout="scroll">
                        <ng-template pTemplate="header">
                          <tr>
                            <th>Dica</th>
                            <th>Descrição</th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                          <tr>
                            <td>{{item.dica}}</td>
                            <td>{{item.descricao}}</td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </ng-template>
                  </p-overlayPanel>

                </div>
              </div>
            </div>
          </form>
        </p-panel>
      </div>

      <div class="col-sm-4">
        <div class="col-sm-12">
          <p-panel header="Como utilizar">
            <img src="../assets/images/whatsapp.gif" alt="" style="padding-bottom: 10px;">
            <div class="col-sm-12">
              <span>Dentro do chat escreva "/"
                é o atalho para inserir o texto rapidamente.
              </span>
            </div>
          </p-panel>
        </div>


      </div>

      <div class="col-sm-12">
        <p-panel header="Respostas cadastradas">
          <div *ngFor="let item of respostas; let i=index " class="p-fluid row p-2">


            <div class="col col-md-4 ">
              <div class="col col-md-12 mb-4">
                <label>Departamento</label>
                <p-dropdown appendTo="body" [options]="dropdownDepartamento" [disabled]="true"
                  [(ngModel)]="item.departamento_id">
                </p-dropdown>
              </div>
              <div class="col col-md-12 mb-4 mt-4">
                <label>Nome do Atalho</label>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    /
                  </span>
                  <input type="text" [(ngModel)]="item.atalho" readonly pInputText>
                </div>
              </div>

            </div>

            <div class="col col-md-7 mb-7 mt-7">
              <label>Mensagem</label>
              <textarea [rows]="5" [cols]="115" readonly pInputTextarea [(ngModel)]="item.texto"></textarea>
            </div>


            <div class="excluir col col-md-1 ">
              <p-button icon="fa fa-times-circle" (click)="delete(item.id)" styleClass="p-button-danger">
              </p-button>
            </div>

          </div>

        </p-panel>
      </div>
    </div>
  </div>
</perfect-scrollbar>
