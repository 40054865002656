import { Component } from '@angular/core';
import moment from 'moment';
import { PrimeNGConfig } from 'primeng/api';
import { ChatService } from 'src/app/core/services/chat.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'app-dashboard-atendimentos',
  templateUrl: './dashboard-atendimentos.component.html',
  styleUrls: ['./dashboard-atendimentos.component.scss']
})
export class DashboardAtendimentosComponent {
  rangeDates: any[] = [];
  dropdownUsuarios = [];
  selectedUsuario = [];

  pt: any = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  selectedPeriodo = 1;
  cards;
  dataSetEvolucao: any = {};
  loading = false;
  dataSourceDepartamentos = [];



  constructor(
    private usuarioService: UsuarioService,
    private chatService: ChatService,
    private config: PrimeNGConfig,
  ) { }


  ngOnInit(): void {
    this.config.setTranslation({
      dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
        'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    });

    this.rangeDates = null;

    this.listUsuarios();

    this.changePeriodo(1);

    setTimeout(() => {
      this.dashboard();
    }, 1000);

  }

  changePeriodo(periodo) {
    this.rangeDates = [];

    this.selectedPeriodo = periodo;

    if (this.selectedPeriodo != null) {
      let today = new Date();
      let endDate = new Date();
      let startDate = new Date();

      if (this.selectedPeriodo < 30) {
        startDate.setDate(today.getDate() - this.selectedPeriodo);
      } else {
        startDate.setMonth(today.getMonth() - 1);
      }

      this.rangeDates.push(startDate);
      this.rangeDates.push(endDate);
    } else {
      this.rangeDates = null;
    }

  }

  classButtonPeriodo(option) {
    if (this.selectedPeriodo == option) {
      return 'btn btn-danger btn-sm btn mr-2 btn-periodo'
    } else {
      return 'btn btn-secondary btn-sm btn mr-2 btn-periodo';
    }
  }


  listUsuarios() {
    this.usuarioService.index().subscribe(json => {
      json.forEach(element => {
        this.dropdownUsuarios.push({ value: element.id, label: element.name });
        this.selectedUsuario = [...this.selectedUsuario, element.id];
      });
    })
  }

  dashboard() {
    this.loading = true;

    let startDate = moment(this.rangeDates[0]).format("YYYY-MM-DD");
    let endDate = moment(this.rangeDates[1]).format("YYYY-MM-DD");

    let request = `?startDate=${startDate}&endDate=${endDate}`;

    // let usuarios = '&' + this.selectedUsuario.map(valor => `usuario[]=${encodeURIComponent(valor)}`).join('&');

    // request += usuarios

    this.chatService.dashboard(request).subscribe(json => {
      this.loading = false;

      this.dataSourceDepartamentos = json.departamentos;

      this.cards = json.cards;

      json.evolucao.forEach(element => {
        element.created_at = moment(element.created_at).format("DD/MM/YYYY");
      });

      this.dataSetEvolucao = null;
      setTimeout(() => {
        this.dataSetEvolucao = { ...this.dataSetEvolucao, series: json.evolucao.map(item => { return item.count }) };
        this.dataSetEvolucao = { ...this.dataSetEvolucao, label: json.evolucao.map(item => { return item.created_at }) };
      }, 300);


    })
  }
}
