<p-tabView>
  <p-tabPanel header="Atendimentos">
    <p-toolbar>
      <div class="p-toolbar-group-left">
        <div class="p-fluid row g-2 p-3">
          <div *ngIf="direitoService.can('RELATORIO_TODOS_ATENDIMENTOS')" class="col-auto">
            <label for="lastname2">Usuário</label>
            <p-multiSelect appendTo="body" class="p-inputtext-sm" [options]="dropdownUsuarios"
              [(ngModel)]="selectedUsuario" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
          </div>

          <div class="col-auto">
            <label for="lastname2">Status</label>
            <p-multiSelect appendTo="body" class="p-inputtext-sm" [options]="dropdownStatus"
              [(ngModel)]="selectedStatus" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
          </div>

          <div class="col-auto">
            <label for="lastname2">Período</label>
            <p-calendar class="p-inputtext-sm" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="rangeDates"
              [locale]="pt" selectionMode="range" [readonlyInput]="false" inputId="range">
            </p-calendar>
          </div>

          <div class="col-auto">
            <p-button icon="ri-search-line" styleClass="p-button-info mt-3" (click)="report()">
            </p-button>
          </div>

          <div class="col-auto">
            <p-button icon="ri-file-excel-line" styleClass="p-button-success mt-3" (click)="exportExcel()">
            </p-button>
          </div>
        </div>
      </div>
    </p-toolbar>

    <div class="flex-fill overflow-hidden">
      <p-table #dt1 [paginator]="false" [showCurrentPageReport]="true" responsiveLayout="scroll" [value]="data"
        [scrollHeight]="'calc(100vh - 250px)'" responsiveLayout="scroll" scrolldirection="both" [autoLayout]="true"
        [rowHover]="true" [scrollable]="true" [loading]="loading" styleClass="p-datatable p-datatable-gridlines"
        scrolldirection="both">

        <ng-template pTemplate="header">
          <tr>
            <th style="width: 250px;">Cliente</th>
            <th style="width: 250px;">Whatsapp contato</th>
            <th style="width: 250px;">Atendente</th>
            <th style="width: 250px;">Situação</th>
            <th style="width: 250px;">Avaliação atendimento</th>
            <th style="width: 250px;">Data/hora abertura</th>
            <th style="width: 250px;">Data/hora atendimento</th>
            <th style="width: 250px;">Data/hora finalizado</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td style="width: 250px;">{{item.cliente}}</td>
            <td style="width: 250px;">{{item.whatsapp_contato}}</td>
            <td style="width: 250px;">{{item.atendente}}</td>
            <td style="width: 250px;">{{item.situacao}}</td>
            <td style="width: 250px;">
              <div *ngIf="item.estrelas" class="d-flex">
                <i *ngFor="let estrela of item.estrelas" class="pi pi-star dourada"></i>
              </div>
              <div *ngIf="item.avaliacao_atendimento == 'Não avaliado'" class="d-flex">
                {{item.avaliacao_atendimento}}
              </div>
            </td>
            <td style="width: 250px;">{{item.data_hora_aberto}}</td>
            <td style="width: 250px;">{{item.data_hora_andamento}}</td>
            <td style="width: 250px;">{{item.data_hora_finalizado}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr *ngIf="data.length>0">
            <td colspan="9">{{data.length}} registros encontrados</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td>Nenhum dado encontrado.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Ranking">
    <app-ranking-avaliacao></app-ranking-avaliacao>
  </p-tabPanel>
</p-tabView>
