import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'chat');
  }

  sendMensagem(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/send/mensagem`, body);
  }

  sendZAPI2(body = null): Observable<any> {
    let url = 'https://api.z-api.io/instances/3A33764C1E26E0A6BE3686AA720AC604/token/DFEA35B5EE647E717B4B7CEE/send-text';
    body = {
      "phone": "5565993628152",
      "message": "Welcome to *Z-API*",
      "delayMessage": 1
    }
    return this.http.post(url, body);
  }

  sendZAPI(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/send/zapi`, body);
  }

  sendZapi(body = null, id = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/send/mensagem/zapi/${id}`, body);
  }

  responderMensagem(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/responder/mensagem`, body);
  }

  responderMensagemFile(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/responder/mensagem/file`, body);
  }

  arquivar(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/arquivar`, body);
  }

  desarquivar(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/desarquivar`, body);
  }

  agendada(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/send/mensagem/agendada`, body);
  }

  createGroup(params = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/create/group`, params);
  }

  // newChat(body = null): Observable<any> {
  //   return this.http.post(`${this.url_base}/${this.prefixo}/new/chat`, body);
  // }

  newChat(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/new/chat/central`, body);
  }

  startConversa(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/new/chat-rapido`, body);
  }

  sendAudio(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/send/audio`, body);
  }

  sendMensagemIntervencao(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/send/mensagem/intervencao`, body);
  }

  sendAudioIntervencao(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/send/audio/intervencao`, body);
  }

  sendFile(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/send/file`, body);
  }

  readMensagem(sessaoId: number): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/read/mensagem/${sessaoId}`);
  }

  historicoChat(chatId: number): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/historico/${chatId}`);
  }

  // getMensagens(chatId: number, numRows: any = 10, finalizados: boolean = false, mensagem_outro_consultor: boolean = false, page = null): Observable<any> {
  //   let url = `${this.url_base}/${this.prefixo}/mensagens/${chatId}/${numRows}`;

  //   let queryParams = '';

  //   if (finalizados) {
  //     queryParams += '&finalizados=true';
  //   }

  //   if (mensagem_outro_consultor != null) {
  //     queryParams += '&mensagem_outro_consultor=' + mensagem_outro_consultor;
  //   }

  //   if (page != null) {
  //     queryParams += '&page=' + page;
  //   }

  //   if (queryParams != '') {
  //     url += '?' + queryParams;
  //   }


  //   return this.http.get(url);
  // }

  getMessages(chatId): Observable<any> {
    // let url = `${this.url_base}/v2/${this.prefixo}/get/messages/${chatId}`;
    let url = `${this.url_base}/v2/${this.prefixo}/get/messages-historico/${chatId}`;
    return this.http.get(url);
  }

  getMessagesHistorico(chatId): Observable<any> {
    let url = `${this.url_base}/v2/${this.prefixo}/get/messages-historico/${chatId}`;
    return this.http.get(url);
  }

  getMembersGroup(groupId: number): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/members-group/${groupId}`);
  }

  getMsgGroupFinished(whatsapp: number): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/group/chat/finished/${whatsapp}`);
  }
  getAtendimentoAtivo(chatId: number): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/atendimento/ativo/${chatId}`);
  }

  getMensagensAtendimento(chatSessaoId: number, numRows: number = 10): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/mensagens/atendimento/${chatSessaoId}/${numRows}`);
  }

  updateSituacaoAtendimento(situacao: any, chatId: number, body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/update/situacao/atendimento/${situacao}/${chatId}`, body);
  }

  filterPaginate(page: number, body = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/list/paginate`;

    if (page !== null) {
      url += '?page=' + page;
    }

    return this.http.post(url, body);
  }

  listGroup(): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/list/group`
    url += '?status=' + 99;
    return this.http.get(url);
  }

  listAllChats(page: number, body: any): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/list/all/chats`;

    if (page !== null) {
      url += '?page=' + page;
    }

    return this.http.post(url, body);
  }

  listChatByUsuario(page: number, nome = null, etapa_funil_id = null, status = '4', whatsapp = null, value = null, arquivado = false, todos = false, rows = null, usuarioFilter = null, departamentoFilter = null, automatico = false, interno = false, pendingReturn = false): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/list/usuario`;

    url += '?status=' + status;

    // if (nome !== null) {
    //   url += '&nome=' + nome;
    // }

    // if (etapa_funil_id !== null) {
    //   url += '&etapa_funil_id=' + etapa_funil_id;
    // }

    // if (whatsapp !== null) {
    //   url += '&whatsapp=' + whatsapp;
    // }


    // if (page !== null) {
    //   url += '&page=' + page;
    // }

    if (rows !== null) {
      url += '&numRows=' + rows;
    }

    if (usuarioFilter !== null) {
      url += '&usuario_id=' + usuarioFilter;
    }

    if (departamentoFilter !== null) {
      url += '&departamento_id=' + departamentoFilter;
    }

    if (value !== null && value != '' && value != undefined) {
      url += '&value=' + value;
    }

    if (automatico) {
      url += '&automatico=' + true;
    }

    url += '&arquivado=' + arquivado;

    url += '&todos=' + todos;

    url += '&interno=' + interno;

    url += '&pendingReturn=' + pendingReturn;

    return this.http.get(url);
  }

  listConversas(atendimento = true, aguardando = true, nome = null, situacao = null, whatsapp = null, mensagem = null, arquivado = false, rows = 10, funilEtapa = null, tag = null, userFilter = null, whatsappFixo = null, aparelho = null, departamento = null, naoLidas = null, semDepartamento = null, finalizadas = false): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/list/all`;

    let request = '?';

    if (atendimento) {
      request += 'atendimento=y';
    } else {
      request += 'atendimento=n';
    }

    if (aguardando) {
      request += '&aguardando=y';
    } else {
      request += '&aguardando=n';
    }

    if (finalizadas) {
      request += '&finalizadas=y';
    } else {
      request += '&finalizadas=n';
    }

    if (rows !== null) {
      request += '&numRows=' + rows;
    }

    if (situacao !== null) {
      request += '&situacao=' + situacao;
    }

    if (nome != null && nome != '') {
      request += '&nome=' + nome;
    }

    if (whatsapp != null && whatsapp != "" && whatsapp != undefined) {
      request += '&whatsapp=' + whatsapp;
    }

    if (mensagem != null) {
      request += '&mensagem=' + mensagem;
    }

    if (funilEtapa != null) {
      request += '&funilEtapa=' + funilEtapa;
    }

    if (tag != null) {
      request += '&tag=' + tag;
    }

    if (userFilter != null) {
      request += '&usuario=' + userFilter;
    }

    if (whatsappFixo != null) {
      request += '&whatsappFixo=' + whatsappFixo;
    }

    if (aparelho != null) {
      request += '&aparelho=' + aparelho;
    }

    if (departamento != null) {
      request += '&departamento=' + departamento;
    }

    if (naoLidas != null) {
      request += '&naoLidas=' + naoLidas;
    }

    if (semDepartamento != null) {
      if (semDepartamento) {
        request += '&semDepartamento=sim';
      } else {
        request += '&semDepartamento=nao';
      }
    }

    request += '&arquivado=' + arquivado;

    if (request != null) {
      url += request;
    }

    return this.http.get(url);
  }

  listChats(nome = null, situacao = null, whatsapp = null, mensagem = null, arquivado = false, rows = 10, funilEtapa = null, tag = null, userFilter = null, whatsappFixo = null, aparelho = null, departamento = null, naoLidas = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/list/usuario`;

    let request = '?';

    if (rows !== null) {
      request += '&numRows=' + rows;
    }

    if (situacao !== null) {
      request += '&situacao=' + situacao;
    }

    if (nome != null) {
      request += '&nome=' + nome;
    }

    if (whatsapp != null && whatsapp != "" && whatsapp != undefined) {
      request += '&whatsapp=' + whatsapp;
    }

    if (mensagem != null) {
      request += '&mensagem=' + mensagem;
    }

    if (funilEtapa != null) {
      request += '&funilEtapa=' + funilEtapa;
    }

    if (tag != null) {
      request += '&tag=' + tag;
    }

    if (userFilter != null) {
      request += '&usuario=' + userFilter;
    }

    if (whatsappFixo != null) {
      request += '&whatsappFixo=' + whatsappFixo;
    }

    if (aparelho != null) {
      request += '&aparelho=' + aparelho;
    }

    if (departamento != null) {
      request += '&departamento=' + departamento;
    }

    if (naoLidas != null) {
      request += '&naoLidas=' + naoLidas;
    }

    request += '&arquivado=' + arquivado;

    if (request != null) {
      url += request;
    }

    return this.http.get(url);
  }

  listChatToMe(pessoaId): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/list-internal/to/me/${pessoaId}`;
    return this.http.get(url);
  }

  listDisponivelByDepartamento(departamentoId: number, page: number): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/list/disponivel/departamento`;

    if (departamentoId !== null) {
      url += '?departamento_id=' + departamentoId;
    }

    // if (page !== null) {
    //   url += '?page=' + page;
    // }

    return this.http.get(url);
  }

  listChatAguardandoAtendimento(departamento: number, page: number, nome = null, whatsapp = null, mensagem = null, funilEtapa = null, tag = null, userFilter = null, aparelho = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/list/aguardando-atendimento`;

    let request = '?';



    if (nome != null) {
      request += '&nome=' + nome;
    }

    if (whatsapp != null && whatsapp != "" && whatsapp != undefined) {
      request += '&whatsapp=' + whatsapp;
    }

    if (mensagem != null) {
      request += '&mensagem=' + mensagem;
    }

    if (funilEtapa != null) {
      request += '&funilEtapa=' + funilEtapa;
    }

    if (tag != null) {
      request += '&tag=' + tag;
    }

    if (userFilter != null) {
      request += '&usuario=' + userFilter;
    }

    if (aparelho != null) {
      request += '&aparelho=' + aparelho;
    }

    if (departamento != null) {
      request += '&departamento=' + departamento;
    }

    if (request != null) {
      url += request;
    }

    // if (page !== null) {
    //   url += '?page=' + page;
    // }

    return this.http.get(url);
  }

  setResponsavelAtendimento(body: any): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/set/responsavel/atendimento`, body);
  }

  newNegociacaoFunil(body: any): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/new/negociacao/funil`, body);
  }

  getUltimoAtendimento(chatId: number): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/ultimo/atendimento/${chatId}`);
  }

  getAllMensagens(chatId: number): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/all/mensagens/${chatId}`);
  }

  getAllMensagensAsync(chat: number, numRows: number) {
    let url = `${this.url_base}/${this.prefixo}/mensagens/${chat}/${numRows}`;
    return this.http.get<any>(url).toPromise().then(data => {

      return data;
    });
  }

  linkAtendimentoUser(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/link/atendimento/usuario`, body);
  }
  
  updateAtendente(chatId, usuarioId): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/update/atendimento/usuario/${chatId}/${usuarioId}`, null);
  }

  aggregation_status(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/aggregation/status`);
  }

  list_novos_por_periodo(periodo1, periodo2): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/novos/periodo/${periodo1}/${periodo2}`);
  }

  time_medio_chat(periodo1, periodo2): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/tempo-medio/${periodo1}/${periodo2}`);
  }

  time_medio_aberto(periodo1, periodo2): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/tempo-medio/aberto/${periodo1}/${periodo2}`);
  }

  time_medio_andamento(periodo1, periodo2): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/tempo-medio/andamento/${periodo1}/${periodo2}`);
  }


  novo_lead(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/add/lead`, body);
  }

  listNovosLeadByUsuario(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/novos-lead/usuario`);
  }

  listAnotacoes(chatId): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/observacoes/${chatId}`);
  }

  storeAnotacao(body: any): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/add/observacao`, body);
  }

  deleteAnotacao(observacaoId, chatId): Observable<any> {
    return this.http.delete(`${this.url_base}/${this.prefixo}/delete/observacao/${observacaoId}/${chatId}`);
  }

  encaminhaMensagem(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/encaminhar/mensagem`, body);
  }

  deleteMessage(mensagem_id): Observable<any> {
    return this.http.delete(`${this.url_base}/${this.prefixo}/deletar/mensagem/${mensagem_id}`);
  }


  listMidias(chatId): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/midia/${chatId}`);
  }

  reportAtendimentoView(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/report/atendimentos/view`, body);
  }

  rankVendedorAvaliacao(startDate = null, endDate = null, ordem = null,): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/report/rank/avaliacao`;
    if (startDate !== null) {
      url += `?startDate=${startDate}`;
    }
    if (endDate !== null) {
      url += `&endDate=${endDate}`;
    }
    if (ordem !== null) {
      url += `&ordem=${ordem}`;
    }
    return this.http.get(url);
  }


  listFinalizados(cliente, usuario = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/list/finalizados/${cliente}`;
    if (usuario !== null) {
      url += `?usuario=${usuario}`;
    }
    return this.http.get(url);
  }

  listPendingReturn(): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/list/pending-return`;
    return this.http.get(url);
  }


  reportRank(ordem = null, startDate = null, endDate = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/report/rank/avaliacao?`;


    if (ordem !== null) {
      url += '&ordem=' + ordem;
    }

    if (startDate !== null) {
      url += '&startDate=' + startDate;
    }

    if (endDate !== null) {
      url += '&endDate=' + endDate;
    }

    return this.http.get(url);
  }

  timeAtendimentoByStatus(startDate, endDate, minutes, status): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/get/time/status/${startDate}/${endDate}/${minutes}/${status}`;
    return this.http.get(url);
  }

  addNotepad(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/add/notepad`, body);
  }

  deleteNotepad(noteId): Observable<any> {
    return this.http.delete(`${this.url_base}/${this.prefixo}/delete/notepad/${noteId}`);
  }

  listGroupsWhatsapp(): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/list/groups/whatsapp`;
    return this.http.get(url);
  }

  dashboard(request = undefined): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/get/dashboard`;
    if (request != undefined) {
      url += request;
    }
    return this.http.get(url);
  }

  sendContact(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/send/contact`, body);
  }

  reportConversasByUsuario(usuario, query): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/report/conversas-by-usuario/${usuario}`

    if (query != undefined) {
      url += query;
    }

    return this.http.get(url);
  }

  downloadMedia(messageWhatsappId): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/media/download/${messageWhatsappId}`);
  }



  listConversasFinalizadas(departamento = null, nome = null, whatsapp = null, mensagem = null, funilEtapa = null, tag = null, userFilter = null, aparelho = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/list/conversas-finalizadas`;

    let request = '?';



    if (nome != null) {
      request += '&nome=' + nome;
    }

    if (whatsapp != null && whatsapp != "" && whatsapp != undefined) {
      request += '&whatsapp=' + whatsapp;
    }

    if (mensagem != null) {
      request += '&mensagem=' + mensagem;
    }

    if (funilEtapa != null) {
      request += '&funilEtapa=' + funilEtapa;
    }

    if (tag != null) {
      request += '&tag=' + tag;
    }

    if (userFilter != null) {
      request += '&usuario=' + userFilter;
    }

    if (aparelho != null) {
      request += '&aparelho=' + aparelho;
    }

    if (departamento != null) {
      request += '&departamento=' + departamento;
    }

    if (request != null) {
      url += request;
    }

    return this.http.get(url);
  }

  getStatusMensagemChat(chatId): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/status-mensagens/${chatId}`);
  }

  deleteObservacaoInterna(chatId, id): Observable<any> {
    return this.http.delete(`${this.url_base}/${this.prefixo}/observacao-interna/${chatId}/${id}`);
  }

  linkDepartamento(chatId, departamentoId): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/link/departamento-chat/${chatId}/${departamentoId}`, null);
  }

  setGatilho(chatId, gatilhoId): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/send/gatilho/${chatId}/${gatilhoId}`, null);
  }



  saveOportunidade(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/oportunidade`, body);
  }

  deleteOportunidade(chatId, etapaId): Observable<any> {
    return this.http.delete(`${this.url_base}/${this.prefixo}/oportunidade/${chatId}/${etapaId}`);
  }

  setNaoLido(chatId, body): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/nao-lido/${chatId}`, body);
  }

  getOthersDep(chatId): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/departamentos/${chatId}`);
  }
  getOthersRes(chatId): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/responsaveis/${chatId}`);
  }

  saveOthersRes(chatId, body: any): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/responsaveis/${chatId}`, body);
  }
  saveOthersDep(chatId, body: any): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/departamentos/${chatId}`, body);
  }
  reabrirConversa(chatId): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/reabrir/atendimento/${chatId}`, null);
  }
}
