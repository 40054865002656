import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'celular' })
export class CelularPipe implements PipeTransform {
  transform(value) {
    if (value != null) {
      var cell = '+' + value.substring(0, 2) + ' (' + value.substring(2, 4) + ') ' + value.substring(4, 9) + '-' + value.substring(9);
      return cell;
    } else {
      return value;
    }
  }
}