import { Component } from '@angular/core';
import { DireitoService } from 'src/app/core/services/direito.service';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent {
  conf;


  constructor(
    public direitoService:DireitoService
  ) {}

  ngOnInit(): void {
    this.conf = JSON.parse(localStorage.getItem('configuracao'));
  }
}
