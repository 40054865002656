<p-table #dt1 [value]="dataSource" styleClass="p-datatable-sm p-datatable-gridlines" [scrollable]="true"
    [scrollHeight]="'calc(100vh - 250px)'" responsiveLayout="scroll" scrolldirection="both" [autoLayout]="true"
    [globalFilterFields]="['nome']" [rowHover]="true">

    <ng-template pTemplate="caption">
        <p-toolbar>
            <div class="p-toolbar-group-left">
                <div class="p-input-icon-left p-fluid p-field p-mr-2">
                    <input pInputText type="text" class="p-inputtext-sm ml-4"
                        (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
                </div>
            </div>

            <div class="p-toolbar-group-right d-flex">
                <button id="btn-export-departamento" type="button" class="btn btn-success btn mr-2"
                    style="margin-right: 5px;" (click)="exportExcel()" ngbTooltip="Exportar para excel"><i
                        class="pi pi-file-export"></i></button>

                <button type="button" class="btn btn-primary btn mr-2" (click)="showForm()" ngbTooltip="Adicionar"><i
                        class="pi pi-plus"></i></button>
            </div>
        </p-toolbar>
    </ng-template>


    <ng-template pTemplate="header">
        <tr c>
            <th>Código</th>
            <th>Descrição</th>
            <th>Cadastro balanceado</th>
            <th style="max-width:100px;"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr class="row-test">
            <td>{{item.id}}</td>
            <td>{{item.descricao}}</td>
            <td>{{item.cadastro_balanceado ? 'Sim' : 'Não'}}</td>
            <td style="max-width:100px;">
                <button type="button" class="btn btn-primary btn-sm mr-2" (click)="showForm(item.id)"
                    ngbTooltip="Editar"><i class="pi pi-pencil"></i></button>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="footer" let-columns>
        <tr>
            <td>
                Total de {{dataSource ? dataSource.length : 0 }} registros.
            </td>
        </tr>
    </ng-template>
</p-table>

<p-dialog header="Tag" [(visible)]="modal" [style]="{width: '35vw'}">
    <form [formGroup]="form" class="row g-2 p-3">
        <div class="col-sm-12">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" id="cadastro_balanceado" formControlName="cadastro_balanceado">
                <label class="form-check-label" for="cadastro_balanceado">Cadastro balanceado</label>
              </div>
        </div>
        <div class="col-sm-12">
            <label for="lastname2">Descrição</label>
            <input pInputText class="form-control p-inputtext-sm" formControlName="descricao" />
            <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
        </div>
    </form>
    <ng-template pTemplate="footer">

        <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;" (click)="modal = false"
            ngbTooltip="Fechar"><i class="pi pi-times"></i></button>

        <button type="button" class="btn btn-success btn mr-2" (click)="save()" ngbTooltip="Salvar"><i
                class="pi pi-save"></i></button>
    </ng-template>
</p-dialog>