import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { ChatService } from 'src/app/core/services/chat.service';

@Component({
  selector: 'app-ranking-avaliacao',
  templateUrl: './ranking-avaliacao.component.html',
  styleUrls: ['./ranking-avaliacao.component.scss']
})
export class RankingAvaliacaoComponent {
  dropDownOrdem: any[] = [
    { value: 'high', label: 'Da melhor para a pior' },
    { value: 'low', label: 'Da pior para a melhor' },
  ];

  ordem: any
  rangeDates: any[] = [];

  loading: boolean = false;

  pt: any = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  data: any[] = [];
  dataRank: any[] = [];
  dataExcel: any[] = [];
  dataExcelRank: any[] = [];

 

  colsRank: any[] = [
    { field: 'usuario', header: 'Atendente' },
    
    { field: 'min', header: 'Menor avaliação' },
    { field: 'max', header: 'Maior avaliação' },
    { field: 'average', header: 'Média' },
    { field: 'count', header: 'Total de atendimentos' },
  ];

  constructor(
    private chatService: ChatService,
  ) { } 

  ngOnInit(): void {
   

    this.rangeDates = null;
  }

  report() {

    let startDate;
    let endDate;

    try {
      startDate = new Date(this.rangeDates[0]).getTime();
    } catch (error) {
      startDate = null;
    }

    try {
      endDate = new Date(this.rangeDates[1]).getTime();
    } catch (error) {
      endDate = null;
    }



    this.loading = true;
    this.chatService.rankVendedorAvaliacao(startDate, endDate, this.ordem).subscribe(json => {
      this.loading = false;
      
      this.data = json;
      
      })
  
  }


  exportExcel() {
    import("xlsx").then(xlsx => {

      this.dataExcelRank = [];

      this.dataRank.forEach((dt, x) => {

        let obj = {};

        this.colsRank.forEach((i) => {

          

          obj[i.header] = `${dt[i.field]}`;

        })

        this.dataExcelRank.push(obj);

      });

      const worksheet = xlsx.utils.json_to_sheet(this.dataExcelRank);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "rank_atendimentos");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
