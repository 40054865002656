import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ChatService } from 'src/app/core/services/chat.service';
import { DepartamentoService } from 'src/app/core/services/departamento.service';
import { DireitoService } from 'src/app/core/services/direito.service';
import { PessoaService } from 'src/app/core/services/pessoa.service';
import { TagService } from 'src/app/core/services/tag.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'app-info-chat',
  templateUrl: './info-chat.component.html',
  styleUrls: ['./info-chat.component.scss']
})
export class InfoChatComponent {
  @Input() chatSelected;

  @Output() onTagUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onDepartamentoUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onUsuarioUpdate: EventEmitter<any> = new EventEmitter();



  dropdownTags = [];
  tagsSelected = [];
  dropdownUsers = [];
  dropdownDepartamentos = [];
  load = false;
  loadingUsers = false;
  tag;

  constructor(
    private tagService: TagService,
    private pessoaService: PessoaService,
    private usuarioService: UsuarioService,
    private chatService: ChatService,
    public direitoService: DireitoService,
    public departamentoService: DepartamentoService,
  ) {

  }


  ngOnInit(): void {
    this.listTags();
    // this.listUsers();
    this.listDepartamentos();

    

  }

  listDepartamentos() {
    this.dropdownDepartamentos = [{ value: null, label: 'Nenhum departamento vinculado' }]
    this.departamentoService.index().subscribe(json => {
      json.map(item => {
        this.dropdownDepartamentos.push({ value: item.id, label: `${item.nome}` });
      });

      let d = this.dropdownDepartamentos.find(d => {return d.label == this.chatSelected['departamento']});
      if (d != undefined) {
        this.chatSelected['departamento_id'] = d['value'];
        this.listUsers();
      }
    })
  }

  listTags() {
    this.tagService.index().subscribe(json => {
      this.dropdownTags = json;
    })
  }

  listUsers() {
    this.loadingUsers = true;
    this.dropdownUsers = [];
    this.dropdownUsers = [{ value: null, label: 'Nenhum responsável informado' }]
    this.usuarioService.grid(this.chatSelected['departamento_id']).subscribe(users => {
      this.loadingUsers = false;
      if (users.length > 0) {
        users.map(user => {
          this.dropdownUsers.push({ value: user.id, label: `${user.name}` });
        })
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getTagsPerson();
  }

  getTagsPerson() {
    this.pessoaService.show(this.chatSelected.codigoCliente).subscribe(json => {
      this.tagsSelected = json.tags;
    })
  }

  selectTag() {
    let isExists = this.tagsSelected.find(t => { return t.id == this.tag?.id });
    if (isExists == undefined) {
      this.load = true;

      this.tagsSelected.push(this.tag);

      this.tag = null;

      let body = {
        pessoa: {
          id: this.chatSelected.codigoCliente,
        },
        tags: this.tagsSelected.map(t => { return t.id })
      }

      this.pessoaService.linkTag(body).subscribe(json => {
        this.onTagUpdate.emit();
        this.load = false;
      })
    }
  }

  removeTag(tag) {
    this.load = true;

    let tags = this.tagsSelected.filter(t => { return t.id != tag.id });
    this.tagsSelected = tags;
    let body = {
      pessoa: {
        id: this.chatSelected.codigoCliente,
      },
      tags: this.tagsSelected.map(t => { return t.id })
    }

    this.pessoaService.linkTag(body).subscribe(json => {
      this.load = false;
      this.onTagUpdate.emit()
    })
  }

  updateUsuario() {
    this.load = true;

    // let body = {
    //   chat_id: this.chatSelected.id,
    //   usuarioId: this.chatSelected.usuario_atendimento_atual_id,
    //   situacao: 0,
    //   changeUser: true
    // }

    this.chatService.updateAtendente(this.chatSelected.id, this.chatSelected.usuario_atendimento_atual_id).subscribe(json => {
      this.load = false;
      this.onUsuarioUpdate.emit();
    }, err => {

    })
  }

  updateDepartamentoChat() {
    this.listUsers();

    this.chatService.linkDepartamento(this.chatSelected['id'], this.chatSelected['departamento_id']).subscribe(json => {
      this.load = false;
      this.onDepartamentoUpdate.emit();
    }, err => {

    })
  }
}
