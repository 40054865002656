import { Component } from '@angular/core';
import { DireitoService } from 'src/app/core/services/direito.service';

@Component({
  selector: 'app-cadastro-page',
  templateUrl: './cadastro-page.component.html',
  styleUrls: ['./cadastro-page.component.scss']
})
export class CadastroPageComponent {

  activetab;

  constructor(
    public direitoService:DireitoService
  ) {

  }

  showAbaChat(index = null) {}
}
 