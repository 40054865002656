import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { forkJoin } from "rxjs";
// import { AutoCadastroService } from "src/app/services/auto-cadastro.service";
// import { ConfiguracaoService } from "src/app/services/configuracao.service";
// import { PessoaService } from "src/app/services/pessoa.service";
import { environment } from "src/environments/environment";
import { PessoaService } from "../core/services/pessoa.service";

@Component({
    selector: "app-auto-cadastro",
    templateUrl: "./auto-cadastro.component.html",
    styleUrls: ["./auto-cadastro.component.css"],
})
export class AutoCadastroComponent implements OnInit {
    form: FormGroup;
    // urlStorage = `${environment.url_storage}`;
    pt = {
        firstDayOfWeek: 0,
        dayNames: [
            "Domingo",
            "Segunda",
            "Terça",
            "Quarta",
            "Quinta",
            "Sexta",
            "Sábado",
        ],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        dayNamesMin: ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sa"],
        monthNames: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ],
        monthNamesShort: [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
        ],
        today: "Hoje",
        clear: "Limpar",
    };

    dropDownSexo: any[] = [
        { value: null, label: "Selecione" },
        { value: 0, label: "Masculino" },
        { value: 1, label: "Feminino" },
    ];

    dropDownTipo: any[] = [
        { value: null, label: "Selecione" },
        { value: 'FISICA', label: "Pessoa Física" },
        { value: 'JURIDICA', label: "Pessoa Juridica" },
    ];

    dropDownUF: any[] = [];

    informativo_cadastro: any;
    banner: any = null;

    device: any;

    empresaCliente = JSON.parse(localStorage.getItem("empresaCliente"));

    dropDownCidade: any[] = [];

    termo_aceite: boolean = false;

    loading: boolean = false;
    loadingCep: boolean = false;

    token: any;
    termo_uso: any;

    showMensagemSalvo: boolean = false;

    emailValido: boolean
    cpfValido: boolean

    linkFacebook: any
    linkInsta: any
    linkTwitter: any
    linkWhats: any
    descricao: any

    storage: string = environment.urlS3;

    constructor(
        private route: ActivatedRoute,
        private _formBuilder: FormBuilder,
        private _pessoaService: PessoaService,
        // private _autoCadastroService: AutoCadastroService,
        // private _configuracaoService: ConfiguracaoService,
        private http: HttpClient
    ) { }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            pessoa: this._formBuilder.group({
                nome: [null, Validators.required],
                plataforma_origem: [null],
                termo_aceite: [null],
                tipo: [null],
                ip_termo_aceite: [null],
                data_hora_aceite: [null],
                isento_ie: false,
                ie: [null],
            }),
            pessoa_fisica: this._formBuilder.group({
                cpf_cnpj: [null, Validators.required],
                nascimento: [null],
            }),
            pessoa_contato: this._formBuilder.group({
                telefone: [null],
                celular: [null, Validators.required],
                email: [null, Validators.required],
            }),
            pessoa_endereco: this._formBuilder.group({
                cep: [null, Validators.required],
                logradouro: [null, Validators.required],
                bairro: [null, Validators.required],
                numero: [null],
                cidade: [null, Validators.required],
                uf: [null, Validators.required],
            }),
        });

        this.token = this.route.snapshot.paramMap.get("token");
        let params = {
            token: this.token
        }
        this._pessoaService.validatorToken(params).subscribe(json => {
            this.descricao = json?.descricao;
            this.informativo_cadastro = json?.nome;
            this.linkFacebook = json?.link_facebook
            this.linkWhats = json?.link_whatsapp
            this.linkInsta = json?.link_instagram 
            this.linkTwitter = json?.link_twitter

            if(json.arquivo){
                this.banner = `${this.storage}/${json.arquivo.path}`;
            } else {
                this.banner = 'assets/images/sem.jpg.gif' 
            }

            if(json.termo){
                this.termo_uso = `${this.storage}/${json.termo.path}`;
            }
          
        })

    

    }

    validarEmail(email) {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        this.emailValido = regex.test(email)
        if (this.emailValido == false) {
            alert('E-mail inválido!')
        }

    }

    validarCPF(cpf) {
        cpf = cpf.replace(/[^\d]/g, '');

        if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
            this.cpfValido = false;
            alert('CPF inválido!')
            return
        }

        let soma = 0;
        let resto;

        for (let i = 1; i <= 9; i++) {
            soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
        }

        resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11) {
            resto = 0;
        }

        if (resto !== parseInt(cpf.substring(9, 10))) {
            this.cpfValido = false;
            alert('CPF inválido!')
            return
        }

        soma = 0;

        for (let i = 1; i <= 10; i++) {
            soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
        }

        resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11) {
            resto = 0;
        }

        if (resto !== parseInt(cpf.substring(10, 11))) {
            this.cpfValido = false;
            alert('CPF inválido!')
            return
        }

        this.cpfValido = true;
    }


    consultaCEP(cep) {

        if (cep !== null) {
            this.loadingCep = true

            // Limpar o CEP para evitar caracteres inválidos
            cep = cep.replace(/\D/g, '');

            // Verificar se o CEP possui os 8 dígitos necessários
            if (cep.length !== 8) {
                console.error('CEP inválido!');
                this.loadingCep = false
                return;
            }

            // URL da API
            const url = `https://viacep.com.br/ws/${cep}/json/`;

            // Fazer a requisição ao ViaCEP
            fetch(url)
                .then(response => response.json())  // Converter a resposta para JSON
                .then(data => {
                    if (data.erro) {
                        alert(data.erro)
                        this.loadingCep = false
                    } else {
                        this.form.get('pessoa_endereco.bairro').setValue(data.bairro)
                        this.form.get('pessoa_endereco.cidade').setValue(data.localidade)
                        this.form.get('pessoa_endereco.logradouro').setValue(data.logradouro)
                        this.form.get('pessoa_endereco.uf').setValue(data.uf)
                        this.loadingCep = false
                    }
                }, err => {
                    alert(err)
                    this.loadingCep = false
                })
                .catch(error => {
                    alert(error)
                    this.loadingCep = false
                });
        }
    }



    salvar() {
        if (this.form.invalid) {
            return;
        }
        this.loading = true;
        let pessoa = this.form.get('pessoa').value
        let fisica = this.form.get('pessoa_fisica').value
        let endereco = this.form.get('pessoa_endereco').value
        let contato = this.form.get('pessoa_contato').value


        let params = {
            data: {
                nome: pessoa.nome,
                cpf_cnpj: fisica.cpf_cnpj,
                nascimento: fisica.nascimento,
                whatsapp: contato.celular,
                email: contato.email,
                cep: endereco.cep,
                logradouro: endereco.logradouro,
                bairro: endereco.bairro,
                municipio: endereco.cidade,
                uf: endereco.uf,
                numero: endereco.numero,
                tipo: pessoa.tipo,
                ie: pessoa.ie,
            },
            token: this.token,
        };

        this._pessoaService.salvarAutoCadastro(params).subscribe(
            (json) => {
                this.showMensagemSalvo = true;

                this.form.reset();

                setTimeout(() => {
                    this.showMensagemSalvo = false;
                }, 3000);

                this.loading = false;
            },
            (err) => {
                this.loading = false;
            }
        );
    }
}


