import { Component } from '@angular/core';
import { ArquivoService } from 'src/app/core/services/arquivo.service';
import { PlanilhaEstacioService } from 'src/app/core/services/planilha-estacio.service';
import { environment } from '../../../environments/environment';
import * as FileSaver from 'file-saver';


@Component({
  selector: 'app-planilha-estacio',
  templateUrl: './planilha-estacio.component.html',
  styleUrls: ['./planilha-estacio.component.scss']
})
export class PlanilhaEstacioComponent {
  displayModal = false;

  uploadedFiles = [];

  dataSource = [];

  fileUpload: any;

  nomeArquivo;

  urlS3: string = environment.urlS3;

  modalHistoricoMensagem = false;

  dataSourceHistorico = [];

  loadingHistorico = false;

  countEnviados = 0;
  countErros = 0;
  countPendentes = 0;

  planilhaSelecionada;

  constructor(
    private arquivoService: ArquivoService,
    private planilhoEstacioService: PlanilhaEstacioService,
  ) {

  }

  ngOnInit(): void {
    this.index();
  }


  save() {
    let body = {
      nome: this.nomeArquivo,
      arquivo_id: this.fileUpload['id']
    }

    this.planilhoEstacioService.save(body, null).subscribe(json => {
      this.displayModal = false;
      this.index();
    })
  }

  index() {
    this.planilhoEstacioService.index().subscribe(json => {
      this.dataSource = json;
    })
  }


  showForm() {
    this.displayModal = true;
  }

  onUpload(event) {
    this.fileUpload = event.files[0];

    let formData = new FormData();

    formData.append('myFile', this.fileUpload);

    this.arquivoService.upload(formData).subscribe(arquivos => {
      this.fileUpload = arquivos[0];
    })
  }

  getHistoricoEnvio(item) {
    this.planilhaSelecionada = item;

    this.modalHistoricoMensagem = true;

    this.countEnviados = 0;
    this.countErros = 0;
    this.countPendentes = 0;

    this.planilhoEstacioService.getHistorico(item['id']).subscribe(json => {

      json.map(item => {
        let statusLabel;
        if (item['status'] == 0) {
          this.countPendentes++;
          statusLabel = 'PENDENTE';
        } else if (item['status'] == 1) {
          this.countEnviados++;
          statusLabel = 'ENVIADO';
        } else if (item['status'] == 2) {
          this.countErros++;
          statusLabel = 'ERRO AO ENVIAR';
        }

        item['statusLabel'] = statusLabel;
      })

      this.dataSourceHistorico = json;

      this.loadingHistorico = false;
    })
  }

  colorGrid(item) {
    if (item.status == 2) {
      return { 'background-color': '#c46d82', 'color': 'white' }
    }
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.dataSourceHistorico);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "disparos_" + new Date().getTime());
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  cancelEnvios() {
    this.planilhoEstacioService.cancelEnvios(this.planilhaSelecionada['id']).subscribe(json => {
      this.getHistoricoEnvio(this.planilhaSelecionada)
    })
  }

  delete(item) {
    this.planilhoEstacioService.delete(item.id).subscribe(json => {
        this.index();
    })
  }
}
