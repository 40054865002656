<div class="container bootstrap snippets bootdeys">
    <div *ngIf="!loading" class="row">

        <div class="col-md-12 col-sm-12 text-start">
            <button type="button" class="btn btn-primary btn mr-2" ngbTooltip="Adicionar uma nova anotação"
                (click)="op.toggle($event)"><i class="pi pi-plus"></i></button>

            <p-overlayPanel #op>
                <ng-template pTemplate>
                    <div>
                        <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="notepad"></textarea>
                    </div>
                    <button type="button" class="btn btn-primary btn mr-2" ngbTooltip="Salvar anotação"
                        (click)="addNotepad()"><i class="pi pi-save"></i></button>
                </ng-template>
            </p-overlayPanel>
        </div>

        <div *ngFor="let item of notepadList" class="col-md-4 col-sm-6 content-card">
            <div class="card-big-shadow">
                <div class="card card-just-text" data-background="color" data-color="yellow" data-radius="none">
                    <div class="text-end m-2" style="cursor: pointer;" (click)="removeNotepad(item)">
                        <i class="pi pi-times" style="color: red;"></i>
                    </div>
                    <div class="content">
                        <!-- <h6 class="category">Best cards</h6>
                        <h4 class="title"><a href="#">Blue Card</a></h4> -->
                        <p class="description">{{item.notepad}}</p>
                    </div>
                </div> <!-- end card -->
            </div>
        </div>

      

        <!-- <div class="col-md-4 col-sm-6 content-card">
            <div class="card-big-shadow">
                <div class="card card-just-text" data-background="color" data-color="yellow" data-radius="none">
                    <div class="content">
                        <h6 class="category">Best cards</h6>
                        <h4 class="title"><a href="#">Green Card</a></h4>
                        <p class="description">What all of these have in common is that they're pulling information out
                            of the app or the service and making it relevant to the moment. </p>
                    </div>
                </div>  
            </div>
        </div>

        <div class="col-md-4 col-sm-6 content-card">
            <div class="card-big-shadow">
                <div class="card card-just-text" data-background="color" data-color="yellow" data-radius="none">
                    <div class="content">
                        <h6 class="category">Best cards</h6>
                        <h4 class="title"><a href="#">Yellow Card</a></h4>
                        <p class="description">What all of these have in common is that they're pulling information out
                            of the app or the service and making it relevant to the moment. </p>
                    </div>
                </div> 
            </div>
        </div> -->

    </div>

    <div *ngIf="loading" class="p-4 text-center">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
            fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    </div>
</div>