<p-dialog [(visible)]="modal" [style]="{width: '50vw'}" (onHide)="cancel()">

    <p-tabView (onChange)="onType($event)">
        <p-tabPanel header="Whatsapp">
            <div class="row p-fluid">
                <div class="col-md-12 mb-2">
                    <label>Digite para pesquisar</label>
                    <p-autoComplete appendTo="body" [(ngModel)]="selectedContact" [showEmptyMessage]="showEmptyMessage"
                        [suggestions]="filteredPessoas" (completeMethod)="filterPessoas($event)" field="nome"
                        [minLength]="1" [forceSelection]="true" (onSelect)="addContact()"></p-autoComplete>
                    <span *ngIf="loading" class="badge badge-soft-danger rounded-pill">Aguarde realizando a
                        pesquisa...</span>
                </div>
            </div>

            <p-table [value]="contactList" styleClass="p-datatable-gridlines" [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="caption">
                    Contatos selecionados
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Nome</th>
                        <th>Whatsapp</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td>{{item.nome}}</td>
                        <td>{{item.celular_principal}}</td>
                        <td>
                            <button pButton pRipple type="button" icon="pi pi-times"
                                class="p-button-rounded p-button-danger" (click)="remove(item)"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <p-tabPanel header="Internamente">
            <p-table [value]="userList" (onRowSelect)="onRowSelect($event)" selectionMode="single"
                styleClass="p-datatable-gridlines" [tableStyle]="{'min-width': '50rem'}"
                [(selection)]="selectedContact">
                <ng-template pTemplate="caption">
                    Contatos selecionados
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Nome</th>
                        <th>Email</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr [pSelectableRow]="item">
                        <td>{{item.name}}</td>
                        <td>{{item.email}}</td>

                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
    </p-tabView>





    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" label="Cancelar e fechar" class="p-button-rounded p-button-danger"
            (click)="cancel()"></button>
        <button pButton pRipple type="button" label="Encaminhar" class="p-button-rounded p-button-success"
            (click)="shared()"></button>
    </ng-template>
</p-dialog>