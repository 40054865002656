import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class CampanhaService extends GenericService{

  constructor(http: HttpClient) {
    super(http, 'campanha');
  }

  showPessoas(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/visualizar/pessoas`, body);
  }

  sendCancel(campanhaId): Observable<any> {
    return this.http.delete(`${this.url_base}/${this.prefixo}/cancel/${campanhaId}`);
  }

  sendMensagem(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/enviar`, body);
  }

  resend(id): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/reenviar/${id}`, null);
  }
}
