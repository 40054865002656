import { AfterViewInit, Component, ElementRef, OnChanges, ViewChild } from '@angular/core';
import { ChatService } from 'src/app/core/services/chat.service';
import { FunilAtendimentoService } from 'src/app/core/services/funil-atendimento.service';
import { TranslateService } from '@ngx-translate/core';
import * as echarts from 'echarts';

@Component({
  selector: 'app-kanban-quadro',
  templateUrl: './kanban-quadro.component.html',
  styleUrls: ['./kanban-quadro.component.scss']
})
export class KanbanQuadroComponent implements AfterViewInit, OnChanges {
  availableChats: any[];
  availableChats2: any[];

  selectedChats: any[] = [];
  jsonData: any[] = [];

  draggedChat: any;

  dropdownFunil = [];
  funilFilter = null;
  etapas: any[] = [];
  negociacaoStart;
  etapaStart;
  load = false;
  tela_charts = true;
  title_funil_selected: any

  @ViewChild('canvasFunil', { static: true }) canvasFunil: ElementRef<HTMLDivElement>;
  chartCanvasFunil: echarts.ECharts;

  constructor(private chatService: ChatService, private funilService: FunilAtendimentoService) { }

  ngOnInit() {
    this.listChatAtendimento();
    this.listAllFunil();
    this.jsonData = []
    this.load = true;
    this.funilService.getAllFunilAtendimento().subscribe((json: any) => {
      this.jsonData = json
      this.tela_charts = true
      this.getChartCanvasFunil();
    })
  }

  ngAfterViewInit(): void {
    this.getChartCanvasFunil();
  }

  ngOnChanges(): void {
    // this.getChartCanvasFunil();
  }

  setFunil(event) {
  }

  listAllFunil() {
    this.funilService.index().subscribe(funilList => {
      this.dropdownFunil = funilList.map(item => { return { value: item.id, label: item.nome } });
    })
  }

  getStatusChat(chat) {
    if (chat.situacao == '0') {
      return 'Aguardando';
    } else if (chat.situacao == '1') {
      return 'Em andamento';
    } else {
      return 'Finalizado'
    }
  }

  getFunilById() {
    this.tela_charts = false
    this.load = true;
    this.funilService.show(this.funilFilter).subscribe(funil => {
      this.load = false;
      funil.etapas.sort((a, b) => a.ordem - b.ordem);
      this.etapas = funil.etapas;

      this.etapas.map(etapa => {
        if (etapa.negociacoes.length == 0) {
          etapa.negociacoes.push({
            "id": null,
            "etapa_funil_id": null,
            "chat_contato_id": null,
            "valor": "0.00",
            "observacao": null,
            "created_at": null,
            "updated_at": null,
          })
        }
      })
    })
  }

  listChatAtendimento() {
    this.chatService.listChats(null, "ATENDIMENTO", null, null, false, 100).subscribe(json => {
      this.availableChats = json.data;
      this.availableChats2 = json.data;
    });
  }

  dragStart(negociacao, etapa) {
    this.negociacaoStart = negociacao
    this.etapaStart = etapa;
  }

  updateEtapaKanban(negociacao, etapa, type = 'add') {
    if (type == 'add') {
      let negociacoes = etapa.negociacoes.filter(n => {return n.id != null});
      negociacoes.push(negociacao);
      etapa.negociacoes = negociacoes;
    } else {
      let negociacoes  = etapa.negociacoes.filter(n => { return n.id != negociacao.id });
      etapa.negociacoes = negociacoes;
      if (etapa.negociacoes.length == 0) {
        this.etapaStart.negociacoes.push({
          "id": null,
          "etapa_funil_id": null,
          "chat_contato_id": null,
          "valor": "0.00",
          "observacao": null,
          "created_at": null,
          "updated_at": null,
        })
      }
    }
  }

  drop(etapa) {
if(this.negociacaoStart.id){
  this.updateEtapaKanban(this.negociacaoStart, this.etapaStart, 'remove');
  this.updateEtapaKanban(this.negociacaoStart, etapa, 'add');

  this.funilService.updateEtapaNegociacao(etapa.id, this.negociacaoStart.id).subscribe(json => {

  });
}
   
  }

  dragEnd() {
    this.draggedChat = null;
  }

  findIndex(product: any) {
    let index = -1;
    for (let i = 0; i < this.availableChats.length; i++) {
      if (product.id === this.availableChats[i].id) {
        index = i;
        break;
      }
    }
    return index;
  }

  backCharts(){
   this.ngOnInit()
   this.ngAfterViewInit()
  }


  getChartCanvasFunil(): void {
   
    if (!this.jsonData) return;

    setTimeout(() => {
      let chartDom = null;
      this.load = false;
      this.jsonData.forEach((funil: any, index: number) => {
        chartDom = document.getElementById(funil.funil);
        if (chartDom) {
          let myChart = echarts.init(chartDom);
          myChart.showLoading();
          myChart.resize();
          let labels = funil.etapas.map((etapa: any) => etapa.label);
          let values = funil.etapas.map((etapa: any) => ({
            name: etapa.label,
            value: etapa.value,
          }));
  
          let option = {
            title: {
              text: funil.funil,
              left: 'center',
            },
            tooltip: {
              trigger: 'item',
              // formatter: '{a} <br/>{b} : {c}',
            },
            toolbox: {
              orient: 'vertical',
              top: 'center',
            },
            dataZoom: [
              {
                type: 'inside'
              },
              {
                type: 'slider'
              }
            ],
            series: [
              {
                  name: false,
                  type: 'funnel',
                  orient: 'horizontal',
                  width: '100%',
                  height: '30%',
                  left: '0%',
                  top: '30%',
                  sort: 'none',
                  data: values
              },
            ],
          };
          myChart.hideLoading();
          myChart.setOption(option, false);
          myChart.resize();
          // myChart.setOption(option);
          
        }
      });
    }, 2000);
  }

  selectFunil(event){
    this.title_funil_selected = event.funil
let retorno = this.dropdownFunil.find(x => x.label == event.funil)
this.funilFilter = retorno.value
this.getFunilById()
  }


}
