<p-table #dt1 [value]="dataSource" styleClass="p-datatable-sm p-datatable-gridlines" [scrollable]="true"
  [scrollHeight]="'calc(100vh - 250px)'" responsiveLayout="scroll" scrolldirection="both" [autoLayout]="true"
  [globalFilterFields]="['nome']" [rowHover]="true">

  <ng-template pTemplate="caption">
    <p-toolbar>
      <div class="p-toolbar-group-left">
        <div class="p-input-icon-left p-fluid p-field p-mr-2">
          <input pInputText type="text" class="p-inputtext-sm ml-4"
            (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
        </div>
      </div>

      <div class="p-toolbar-group-right d-flex">
        <button id="btn-export-departamento" type="button" class="btn btn-success btn mr-2" style="margin-right: 5px;"
          (click)="exportExcel()" ngbTooltip="Exportar para excel"><i class="pi pi-file-export"></i></button>

        <button type="button" class="btn btn-primary btn mr-2" (click)="showForm()" ngbTooltip="Adicionar"><i
            class="pi pi-plus"></i></button>
      </div>
    </p-toolbar>
  </ng-template>


  <ng-template pTemplate="header">
    <tr c>
      <th>Código</th>
      <th>Nome</th>
      <th style="max-width:100px;"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr class="row-test">
      <td>{{item.id}}</td>
      <td>{{item.nome}}</td>
      <td style="max-width:100px;">
        <button type="button" class="btn btn-primary btn-sm mr-2" (click)="showForm(item.id)" ngbTooltip="Editar"><i
            class="pi pi-pencil"></i></button>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td>
        Total de {{dataSource ? dataSource.length : 0 }} registros.
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog header="Link" [(visible)]="modal" [style]="{width: '35vw'}">
  <form [formGroup]="form" class="row g-2 p-3">
    <div class="col-sm-12">
      <label for="nome">Nome</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="nome" />
      <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
    </div>
    <div class="col-sm-12 p-fluid">
      <label for="descricao">Descrição</label>
      <textarea rows="5" cols="30" pInputTextarea formControlName="descricao" class="p-inputtext-sm"></textarea>
    </div>
    <div class="col-sm-12">
      <label for="instagram">Link Instagram</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="link_instagram" />
    </div>
    <div class="col-sm-12">
      <label for="facebook">Link Facebook</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="link_facebook" />
    </div>
    <div class="col-sm-12">
      <label for="twitter">Link Twitter</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="link_twitter" />
    </div>
    <div class="col-sm-12">
      <label for="whatsapp">Link Whatsapp</label>
      <input pInputText class="form-control p-inputtext-sm" formControlName="link_whatsapp" />
    </div>
    <div class="col-sm-12">
      <p-fileUpload [multiple]="false" name="myFiles[]" [url]="urlUpload" (onUpload)="onBasicUploadLink($event)"
        chooseIcon="pi pi-paperclip" [auto]="true" chooseLabel="Selecione o banner">
        <ng-template pTemplate="content">
          <ul *ngIf="filesLink.length > 0">
            <li *ngFor="let file of filesLink">{{file.nome}}</li>
          </ul>
        </ng-template>
      </p-fileUpload>
    </div>
    <div class="col-sm-12">
      <p-fileUpload [multiple]="false" name="myFiles[]" [url]="urlUpload" (onUpload)="onBasicUploadTermo($event)"
        chooseIcon="pi pi-paperclip" [auto]="true" chooseLabel="Selecione o termo de uso">
        <ng-template pTemplate="content">
          <ul *ngIf="filesTermo.length > 0">
            <li *ngFor="let file of filesTermo">{{file.nome}}</li>
          </ul>
        </ng-template>
      </p-fileUpload>
    </div>
  </form>

  <div *ngIf="form.get('id').value != null" class="col-sm-12">
    <label for="nome">Link completo</label>
    <input disabled pInputText class="form-control p-inputtext-sm" [(ngModel)]="linkFull" />
  </div>

  <ng-template pTemplate="footer">

    <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;" (click)="modal = false"
      ngbTooltip="Fechar"><i class="pi pi-times"></i></button>

    <button type="button" class="btn btn-success btn mr-2" (click)="save()" ngbTooltip="Salvar"><i
        class="pi pi-save"></i></button>
  </ng-template>
</p-dialog>