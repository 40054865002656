<div [ngStyle]="styleResponse()" class="_1EbXp ellipsis" role="button" aria-label="Mensagem citada" tabindex="0"
  (click)="scroll()">
  <span class="bg-color-6 _1Jh1l"></span>
  <div class="yKTUI _3pMOs">
    <div class="yKTUI _1JeGx">
      <div class="_37DQv" dir="ltr" role="button">
        <div class="d-flex align-items-between ellipsis">
          <div class="p-2 ellipsis">
            <span dir="auto" aria-label="" class="quoted-mention _11JPr ellipsis"
              style="min-height: 0px; color: black;">{{response.conteudo}}</span>
          </div>

          <!-- <div *ngIf="type == 1" class="p-2" (click)="closeBoxResponse()" style="cursor: pointer;">
            <button class="btn btn-danger">X</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <div *ngIf="align !== 'right'" class="_1EbXp" role="button" aria-label="Mensagem citada" tabindex="0" style="margin-bottom: 20px;
border-right: 3px solid;
border-color: white;
border-radius: 5px;
padding: 5px;
background-color: #9590da;
">

    <span class="bg-color-6 _1Jh1l" style=""></span>
    <div class="yKTUI _3pMOs">
        <div class="yKTUI _1JeGx">
            <div class="_3IzYj color-6 p357zi0d" role=""><span dir="auto" aria-label="" class="_3FuDI _11JPr"
                    style="min-height: 0px;">Jean Número 66</span></div>
            <div class="_37DQv" dir="ltr" role="button"><span dir="auto" aria-label="" class="quoted-mention _11JPr"
                    style="min-height: 0px;">Vc mesmo que eu queria</span></div>
        </div>
    </div>
</div> -->
