import { Component, Input, SimpleChanges } from '@angular/core';
import * as echarts from 'echarts';

@Component({
  selector: 'app-chart-line',
  templateUrl: './chart-line.component.html',
  styleUrls: ['./chart-line.component.scss']
})
export class ChartLineComponent {
  @Input() chartId = 'main'
  @Input() series = null;
  @Input() label = null;

  ngOnInit(): void {
    setTimeout(() => {
      this.buildChart();
    }, 500);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.buildChart();
  }

  buildChart() {
    

    if (this.series == null) {
      return;
    }

    var chartDom = document.getElementById(this.chartId);
    var myChart = echarts.init(chartDom);
    var option;

    option = {
      xAxis: {
        type: 'category',
        data: this.label
      },
      tooltip: {
        trigger: 'axis'
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: this.series,
          type: 'line',
          smooth: true
        }
      ]
    };

    option && myChart.setOption(option);
  }
}
