import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlanilhaEstacioService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'planilha-estacio');
  }

  getHistorico(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/historico-envio/${id}`);
  }

  cancelEnvios(id): Observable<any> {
    return this.http.delete(`${this.url_base}/${this.prefixo}/cancelar-envio/${id}`);
  }

  delete(id): Observable<any> {
    return this.http.delete(`${this.url_base}/${this.prefixo}/${id}`);
  }
}
