import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-resposta-mensagem',
  templateUrl: './resposta-mensagem.component.html',
  styleUrls: ['./resposta-mensagem.component.scss']
})
export class RespostaMensagemComponent {

  @Input() align;
  @Input() response;
  @Output() onScroll: EventEmitter<any> = new EventEmitter();
  @Output() onCloseBoxResponse: EventEmitter<any> = new EventEmitter();

  @Input() type = 0;


  styleResponse() {
    if (this.align == 'right') {
      return {
        "border-left": "3px solid",
        "border-color": "green",
        "border-radius": "5px",
        "padding": "5px",
        "background-color": "#fcfcfc",
        "display": "block"
      }
    } else {
      return {
        "border-right": "3px solid",
        "border-color": "white",
        "border-radius": "5px",
        "padding": "5px",
        "background-color": "#9590da",
        "display": "block"
      }
    }
  }

  scroll() {
    this.onScroll.emit(this.response.id);
  }

  closeBoxResponse() {
    this.onCloseBoxResponse.emit();
  }
}
