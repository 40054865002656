<div class="row">
    <div class="col-md-3">
        <div class="row">
            <div class="mb-3">
                <label for="inputtext">Usuário</label>
                <p-dropdown appendTo="body" class="p-inputtext-sm" [options]="dropdownUsuarios"
                    [(ngModel)]="usuario"></p-dropdown>
                <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
            </div>

            <div class="p-fluid mb-3">
                <label for="lastname2">Destinatário</label>
                <p-autoComplete appendTo="body" [(ngModel)]="destino" [showEmptyMessage]="showEmptyMessage"
                    [suggestions]="filteredPessoas" (completeMethod)="filterPessoas($event)" field="nome"
                    class="p-inputtext-sm" [minLength]="1" [forceSelection]="true"></p-autoComplete>
            </div>

            <div class="mb-3">
                <label for="inputtext">Palavra chave</label>
                <input pInputText class="form-control p-inputtext-sm" [(ngModel)]="keySearch" />
            </div>

            <div class="p-fluid mb-3">
                <label for="lastname2">Período</label>
                <p-calendar class="p-inputtext-sm" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="rangeDates"
                    selectionMode="range" [readonlyInput]="false" inputId="range">
                </p-calendar>
            </div>

            <div class="col-auto">
                <button pButton [disabled]="usuario == null" icon="ri-search-line" styleClass="p-button-info mt-3" (click)="report()">
                </button>
            </div>
        </div>
    </div>

    <div class="col-md-9">

        <div *ngIf="loading" class="text-center">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
        </div>

        <perfect-scrollbar class="scroll" data-simplebar>
            <div class="card">

                <p-table  *ngIf="dataSource.length == 0 && !loading" [value]="chat?.mensagens_desc" responsiveLayout="scroll">
                    <ng-template pTemplate="caption">
                        <div class="table-header">
                            <ul class="list-group">
                                <li class="list-group-item"><label><b>Destinatário:</b></label>
                                </li>
                                <li class="list-group-item"><label><b>Usuário:</b></label>
                                </li>
                                <li class="list-group-item"><label><b>Início da conversa:</b></label>
                                </li>
                            </ul>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Emitente</th>
                            <th>Tipo</th>
                            <th>Conteúdo</th>
                            <th>Data envio</th>
                            <th></th>
                        </tr>
                    </ng-template>
                </p-table>


                <div class="card mt-4" *ngFor="let chat of dataSource">
                    <div class="card-body">
                        <div class="row">
                            <p-table [loading]="loading" [value]="chat?.mensagens_desc" responsiveLayout="scroll">
                                <ng-template pTemplate="caption">
                                    <div class="table-header">
                                        <ul class="list-group">
                                            <li class="list-group-item"><label><b>Destinatário:</b></label>
                                                {{chat.cliente.nome}}</li>
                                            <li class="list-group-item"><label><b>Usuário:</b></label>
                                                {{chat.usuario.name}}
                                            </li>
                                            <li class="list-group-item"><label><b>Início da conversa:</b></label>
                                                {{chat.created_at}}</li>
                                        </ul>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Emitente</th>
                                        <th>Tipo</th>
                                        <th>Conteúdo</th>
                                        <th>Data envio</th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-item>
                                    <tr>
                                        <td>{{item.nome_emitente}}</td>
                                        <td>{{item.tipo}}</td>
                                        <td *ngIf="item.tipo == 'text'">{{item.conteudo}}</td>
                                        <td *ngIf="item.tipo !== 'text'">
                                            <a target="_blank" href="{{urlS3}}/{{item.conteudo}}">{{item.conteudo}}</a>
                                        </td>
                                        <td>{{item.created_at | date:'dd/MM/yyyy HH:mm'}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>

                        </div>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>

    </div>
</div>