import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanQuadroComponent } from './kanban-quadro/kanban-quadro.component';
import { NgbTooltipModule, NgbDropdownModule, NgbAccordionModule, NgbModalModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';


import { DragDropModule } from 'primeng/dragdrop';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { BadgeModule } from 'primeng/badge';
import {ProgressSpinnerModule} from 'primeng/progressspinner';


@NgModule({
  declarations: [KanbanQuadroComponent],
  imports: [
    CommonModule,
    DragDropModule,
    TableModule,
    ToolbarModule,
    ButtonModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    TranslateModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgbModalModule,
    NgbCollapseModule,
    BadgeModule,
    ProgressSpinnerModule
  ],
  exports: [KanbanQuadroComponent]
})
export class KanbanModule { }
