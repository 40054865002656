import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})

export class RespostaRapidaService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'resposta-rapida');
  }

  delete(id = null): Observable<any> {
    return this.http.delete(`${this.url_base}/${this.prefixo}/${id}`);
  }

  autoCompleteResposta(value: any): Observable<any> {
    if (value !== '') {
      return this.http.get(`${this.url_base}/${this.prefixo}/autocomplete/${value}`);
    } else {
      return this.http.get(`${this.url_base}/${this.prefixo}/autocomplete`);
    }
  }

}
