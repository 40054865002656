import { Component } from '@angular/core';

@Component({
  selector: 'app-lead-ticket',
  templateUrl: './lead-ticket.component.html',
  styleUrls: ['./lead-ticket.component.scss']
})
export class LeadTicketComponent {

}
