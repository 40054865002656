import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { ConfiguracaoService } from 'src/app/core/services/configuracao.service';
import { OportunidadeEtapaService } from 'src/app/core/services/oportunidade-etapa.service';

@Component({
  selector: 'app-parameterization',
  templateUrl: './parameterization.component.html',
  styleUrls: ['./parameterization.component.scss']
})
export class ParameterizationComponent {
  form!: FormGroup;
  formRdStation!: FormGroup;

  stateOptions = [{ label: 'Não', value: false }, { label: 'Sim', value: true }];

  configuracao: any[] = [];

  loading = false;


  dropdownOportunidadeEtapa: any[] = [{ value: null, label: 'Não criar gerar negociação' }];
  dropDownVisualizacao: any[] = [{ value: 0, label: 'KanBan' }, { value: 1, label: 'Grid' }];
  direitoInserir: any[] = [];


  dropdownIntegracaoWhatsapp = [
    { value: 'ZAPI', label: 'Z-API' },
    { value: 'EVOLUTION', label: 'Evolution-API' },
    { value: 'EVOLUTION-BRASIL', label: 'Evolution-API (API BRASIL)' },
  ]

  dropdownConfTypebot = [
    { value: 'CONFIGURACAO', label: 'Configurações' },
    { value: 'CANAL_ATENDIMENTO', label: 'Canal de atendimento' },
  ]

  arrayHorarioFuncionamento = [];
  arrayDiasSemana = [
    { value: 1, label: 'Segunda' },
    { value: 2, label: 'Terça' },
    { value: 3, label: 'Quarta' },
    { value: 4, label: 'Quinta' },
    { value: 5, label: 'Sexta' },
    { value: 6, label: 'Sabado' },
    { value: 7, label: 'Domingo' },
  ]

  constructor(
    private _configuracaoService: ConfiguracaoService,
    private _formBuilder: FormBuilder,
    private _oportunidadeEtapaService: OportunidadeEtapaService
  ) { }

  ngOnInit(): void {
    let direitos = JSON.parse(localStorage.getItem('direito'));
    this.direitoInserir = direitos.find(item => item.flag == 'CONFIGURACAO_INSERIR')

    this.form = this._formBuilder.group({
      id: [null],
      pasta_arquivo: [null],
      tempo_disponibilizar_chat: [null],
      status_aberto_novo_chat: [true],
      status_andamento_usuario_responder_chat: [true],
      status_andamento_bot_responder_chat: [true],
      etapa_negociacao_novo_atendimento: [true],
      permite_enviar_mensagem_externa: [false],
      transferir_mensagem_direcionar_chat: [false],
      manter_vendedor_ultimo_atendimento: [false],
      desarquivar_chat_receber_mensagem: [false],
      visualizacao_padrao_oportunidade: 0,
      enviar_avaliacao_atendimento: [false],
      enviar_protocolo_atendimento: [false],
      finalizar_chat_apos_classificar: [false],
      mensagem_avaliacao_atendimento: [null],
      tempo_encerrar_chat: [null],
      integrador_whatsapp: [null],
      tempo_direcionar_chat: [null],
      quantidade_disparo_campanha: [null],
      // logo_empresa_link: [null],
      conf_typebot: [null],
      habilitar_horario_funcionamento: [null],
      mensagem_funcionamento: [null],
      s3: [false],
      client_id_rd_station: [null],
      secret_key_rd_station: [null],
      url_callback_rd_station: [null],
      habilitar_integracao_rd_station: [false],
      direcionar_vendedor_automatico: [false],
      habilita_ecommerce: [false],
      url_ecommerce: [false],
      modulo_agenda: [false],
      chat_interno: [false],
    })



    forkJoin([this._oportunidadeEtapaService.listAll()]).subscribe(json => {
      let etapas = json[0];
      etapas.forEach((element: any) => {
        this.dropdownOportunidadeEtapa.push({ value: element.id, label: element.descricao })
      });

      this._configuracaoService.index().subscribe(json => {
        this.form.patchValue(json[0]);
        this.getHorarioFuncionamento();
      })
    })

    this.addHorarioFuncionamento();
  }

  getHorarioFuncionamento() {
    this._configuracaoService.getHorarioFuncionamento().subscribe(json => {
      

      json.forEach(element => {
        var timeStr = element.abertura;
        var timeParts = timeStr.split(':');
        var hours = parseInt(timeParts[0], 10);
        var minutes = parseInt(timeParts[1], 10);

        var date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        element.abertura = date;

        var timeStr = element.fechamento;
        var timeParts = timeStr.split(':');
        var hours = parseInt(timeParts[0], 10);
        var minutes = parseInt(timeParts[1], 10);

        var date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        element.fechamento = date;
      });

      this.arrayHorarioFuncionamento = json;
    })
  }

  addHorarioFuncionamento() {
    this.arrayHorarioFuncionamento.push({
      dia_semana: null,
      abertura: null,
      fechamento: null
    })
  }

  save() {
    this.loading = true;

    if (this.form.get('habilitar_horario_funcionamento').value && this.arrayHorarioFuncionamento.length > 0) {
      this.arrayHorarioFuncionamento.map(item => {
        item.abertura = moment(item.abertura).format("HH:mm");
        item.fechamento = moment(item.fechamento).format("HH:mm");
      })
    }

    let body: any = {
      configuracao: this.form.value,
      horario_funcionamento: this.arrayHorarioFuncionamento
    }

    this._configuracaoService.update(body, this.form.get('id')?.value).subscribe(json => {
      localStorage.setItem('configuracao', JSON.stringify(json));
      window.location.reload();
      this.loading = false;
    }, err => {
      this.loading = false;
    })
  }

  removeHorarioFuncionamento(item) {
    let horarios = this.arrayHorarioFuncionamento.filter(h => {return h != item});
    this.arrayHorarioFuncionamento = horarios;
  }
}
