import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
import { PrimeNGConfig } from 'primeng/api';
import { ChatService } from 'src/app/core/services/chat.service';
import { DireitoService } from 'src/app/core/services/direito.service';
import { PessoaService } from 'src/app/core/services/pessoa.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-conversa',
  templateUrl: './report-conversa.component.html',
  styleUrls: ['./report-conversa.component.scss']
})
export class ReportConversaComponent {
  dataSource = [];
  rangeDates: any[] = [];
  usuario;
  destino;
  dropdownUsuarios = [];
  filteredPessoas = [];
  showEmptyMessage = true;
  keySearch;
  loading = false;
  urlS3 = environment.urlS3;


  constructor(
    private usuarioService: UsuarioService,
    private chatService: ChatService,
    private config: PrimeNGConfig,
    public direitoService: DireitoService,
    public pessoaService: PessoaService,
    public formBuilder: FormBuilder
  ) { }


  ngOnInit(): void {
    this.config.setTranslation({
      dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
        'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    });

    this.listUsuarios();

    let currentDate = new Date();
    let startDate =  currentDate;
    startDate.setDate(currentDate.getMonth() - 1);
    let endDate = new Date();

    this.rangeDates.push(startDate);
    this.rangeDates.push(endDate);
  }

  listUsuarios() {
    this.usuarioService.index().subscribe(json => {
      json.forEach(element => {
        this.dropdownUsuarios.push({ value: element.id, label: element.name });
      });
    });
  }

  filterPessoas(event) {

    let query = event.query;

    let body = {
      nome: query
    }

    this.pessoaService.autoCompletePessoa(body).subscribe(json => {
      this.filteredPessoas = json;
    })
  }

  report() {
    this.loading = true;

    let request = '?';

    if (this.rangeDates.length > 0) {
      if (this.rangeDates[0]) {
        request += `&startDate=${moment(this.rangeDates[0]).format("YYYY-MM-DD")}`;
      }
  
      if (this.rangeDates[1]) {
        request += `&endDate=${moment(this.rangeDates[1]).format("YYYY-MM-DD")}`;
      }
    }

    if (this.destino) {
      request += `&destino=${this.destino.id}`;
    }

    if (request == '?') {
      request = null;
    }

    this.chatService.reportConversasByUsuario(this.usuario, request).subscribe(json => {
      this.loading = false;
      this.dataSource = json;
    })
  }
}
