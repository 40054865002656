import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';

import { IndexComponent } from './index/index.component';
import { InstanciaWhatsappComponent } from '../instancia-whatsapp/instancia-whatsapp.component';

const routes: Routes = [
    {
        path: '',
        component: IndexComponent,
        children: [
            {
                path: 'apps',
                children: [
                    {
                        path: "canal-atendimento",
                        children: [
                          { path: "lista", component: InstanciaWhatsappComponent },
                          { path: "form", component: InstanciaWhatsappComponent },
                          { path: "form/:id", component: InstanciaWhatsappComponent },
                        ],
                      },
                      
                ],
                
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes),
        FormsModule,ReactiveFormsModule],
    exports: [RouterModule]
})

export class ChatRoutingModule { }
