<div *ngIf="page == 'LIST'" class="p-fluid  row mt-2" style="padding: 0;
margin: 0;">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body row" style="background-color: #edeff1;">
        <div class="d-flex justify-content-start ofset-6 col-md-2 mb-12">
          <div *ngIf="direitoService.can('INSERIR_GATILHOS_CHAT')" class="text-md-start mt-3 mt-md-0">
            <a (click)="adicionar()" class="btn btn-success"><i class="mdi mdi-plus-circle me-1"></i>
              Adicionar</a>
          </div>


        </div>
      </div>
    </div> <!-- end card -->

  </div>

  <p-table #dt1 [value]="dataSource" styleClass="p-datatable-sm p-datatable-gridlines" [scrollable]="true"
    [scrollHeight]="'calc(100vh - 250px)'" responsiveLayout="scroll" scrolldirection="both" [autoLayout]="true"
    [globalFilterFields]="['nome']" [rowHover]="true">

    <!-- <ng-template pTemplate="caption">
<p-toolbar>
    <div class="p-toolbar-group-left">
        <div class="p-input-icon-left p-fluid p-field p-mr-2">
            <input pInputText type="text" class="p-inputtext-sm ml-4"
                (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
        </div>


    </div>

    <div class="p-toolbar-group-right d-flex">

        <button id="btn-export-departamento" type="button" class="btn btn-success btn mr-2"
            style="margin-right: 5px;" (click)="exportExcel()" ngbTooltip="Exportar para excel"><i
                class="pi pi-file-export"></i></button>

        <button *ngIf="direitoService.can('DEPARTAMENTO_INSERIR')" type="button"
            class="btn btn-primary btn mr-2" (click)="showForm()" ngbTooltip="Adicionar"><i
                class="pi pi-plus"></i></button>
    </div>
</p-toolbar>
</ng-template> -->


    <ng-template pTemplate="header">
      <tr c>
        <th>Código</th>
        <th>Nome</th>
        <th>Departamento</th>
        <th style="max-width:100px;"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr class="row-test">
        <td>{{item.id}}</td>
        <td>{{item.nome}}</td>
        <td>{{item.departamento}}</td>
        <td style="max-width:100px;">
          <button *ngIf="direitoService.can('ALTERAR_GATILHOS_CHAT')" type="button" class="btn btn-primary btn-sm"
            (click)="show(item.id)" ngbTooltip="Editar" style="margin-right: 5px;"><i class="pi pi-pencil"></i></button>
          <button  type="button" class="btn btn-primary btn-sm"
            (click)="duplicate(item.id)" ngbTooltip="Duplicar"><i class="pi pi-copy"></i></button>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer" let-columns>
      <tr>
        <td>
          Total de {{dataSource ? dataSource.length : 0 }} registros.
        </td>
      </tr>
    </ng-template>
  </p-table>

</div>


<div *ngIf="page == 'FORM'" class="p-fluid  row mt-2">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body row" style="background-color: #edeff1;">
        <div class="d-flex justify-content-start ofset-6 col-md-12 mb-12">
          <div class="text-md-start mt-3 mt-md-0">
            <a (click)="save()" class="btn btn-success" style="margin-right: 5px;"><i
                class="mdi mdi-plus-circle me-1 mr-2"></i>
              Salvar</a>
              
            <a *ngIf="form.get('id').value !== null" (click)="save('duplicate')" class="btn btn-success" style="margin-right: 5px;"><i
                class="mdi mdi-copy"></i>
              Duplicar</a>

            <a (click)="page = 'LIST'" class="btn btn-danger">
              Voltar</a>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div style="overflow: auto; height: calc(100vh - 270px);">
    <form [formGroup]="form" class="row g-2">

      <div class="col-sm-12">
        <label for="lastname2">Nome</label>
        <input pInputText class="form-control p-inputtext-sm" formControlName="nome" />
        <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
      </div>

      <div class="col-sm-12">
        <label for="lastname2">Departamento</label>
        <p-dropdown appendTo="body" placeholder="Selecione um departamento" [options]="dropdownDepartamento"
          formControlName="departamento_id"></p-dropdown>
        <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
      </div>

      <div class="col-sm-2">
        <p-splitButton appendTo="body" label="Adicionar ação" [model]="items" styleClass="p-button-raised mr-2 mb-2">
        </p-splitButton>
      </div>

    </form>

    <div *ngFor="let item of acaoList" class="mt-4 row">
      <div class="col-sm-10 row">
        <label>{{item['descricao']}}</label>

        <textarea *ngIf="item.tipo == 1" rows="5" cols="30" pInputTextarea [(ngModel)]="item.valor"
          class="p-inputtext-sm"></textarea>

        <p-dropdown appendTo="body" placeholder="Selecione um usuario" [disabled]="!item.habilitado"
          *ngIf="item.tipo == 2" [options]="dropdownUsuario" [(ngModel)]="item.valor"></p-dropdown>

        <p-multiSelect *ngIf="item.tipo == 3" [options]="dropdownTag" [(ngModel)]="item.valor"
          defaultLabel="Selecione as tags" display="chip"></p-multiSelect>

        <p-dropdown appendTo="body" placeholder="Selecione um departamento" *ngIf="item.tipo == 4 || item.tipo == 9"
          [options]="dropdownDepartamento" [(ngModel)]="item.valor"></p-dropdown>

        <label *ngIf="item.tipo == 4" class="form-check-label" for="customCheck2">Ativar rodizio
          (Automatico) entre os usuários do departamento</label>

        <p-checkbox *ngIf="item.tipo == 4" [(ngModel)]="item.rodizio" [binary]="true" inputId="binary"></p-checkbox>

        <p-dropdown appendTo="body" placeholder="Selecione um status" *ngIf="item.tipo == 5" [options]="dropdownStatus"
          [(ngModel)]="item.valor"></p-dropdown>

        <p-dropdown *ngIf="item.tipo == 11" appendTo="body" placeholder="Selecione uma etapa" [options]="dropdownFunil"
          [(ngModel)]="item.valor"></p-dropdown>

        <p-fileUpload *ngIf="item.tipo == 6" #myFile name="myFile" chooseLabel="Selecione um arquivo" [auto]="true"
          [customUpload]="true" (uploadHandler)="uploadHandlerDoc($event, item)">

          <ng-template pTemplate="content">
            <ul *ngIf="item.files">

              <div *ngFor="let file of item.files" class="d-flex justify-content-between">
                <div>
                  <li>{{file.name || file.original_name}} </li>
                </div>

                <div>
                  <button pButton pRipple type="button" icon="pi pi-times" class="-button-primary"
                    (click)="deleteFile(item)"></button>
                </div>
              </div>


            </ul>
          </ng-template>
        </p-fileUpload>

        <p-dropdown appendTo="body" placeholder="Selecione um canal de atendimento"
          *ngIf="item.tipo == 7 || item.tipo == 10" [options]="dropdownCanalAtendimento" [(ngModel)]="item.valor">
        </p-dropdown>

        <div class="col-sm-6" *ngIf="item.tipo == 8">
          <p-dropdown appendTo="body" placeholder="Selecione um campo persnalizado"
            [options]="dropdownCamposPersonalizados" [(ngModel)]="item.objeto"></p-dropdown>
        </div>

        <div class="col-sm-6" *ngIf="item.tipo == 8">
          <input pInputText class="form-control" [(ngModel)]="item.valor" />
        </div>

      </div>

      <div class="col-sm-2 btns">

        <button *ngIf="item.tipo == 1" pTooltip="Dicionário
        de palavras chaves" pButton pRipple type="button" icon="pi pi-info-circle"
          class="p-button-rounded p-button-primary" (click)="ag.toggle($event)"></button>

        <button pButton pRipple type="button" style="margin-left: 5px;" icon="pi pi-times"
          class="p-button-rounded p-button-danger" (click)="deleteItem(item)"></button>





        <p-overlayPanel #ag [showCloseIcon]="true" [style]="{width: '450px'}">
          <ng-template pTemplate>
            <p-table [value]="dicionario" responsiveLayout="scroll">
              <ng-template pTemplate="header">
                <tr>
                  <th>Dica</th>
                  <th>Descrição</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item>
                <tr>
                  <td>{{item.dica}}</td>
                  <td>{{item.descricao}}</td>
                </tr>
              </ng-template>
            </p-table>
          </ng-template>
        </p-overlayPanel>
      </div>

    </div>
  </div>

</div>