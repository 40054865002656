import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class DepartamentoService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'departamento');
  }

  aggregation_chat_situacao(departamentoId): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/aggregation/chat/${departamentoId}`);
  }

  notificacao(departamentoId): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/notificacao/${departamentoId}`);
  }
}
