import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CelularPipe } from './pipes/celular.pipe';

@NgModule({
  declarations: [
    CelularPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [CelularPipe]
})
export class CoreModule { }
