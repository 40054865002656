import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'configuracao');
  }
  
  getConfigs(subdominio): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/aplicacao/${subdominio}`);
  }

  getHorarioFuncionamento(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/horario-funcionamento`);
  }
}
