import { Component } from '@angular/core';
import moment from 'moment';
import { PrimeNGConfig } from 'primeng/api';
import { ChatService } from 'src/app/core/services/chat.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import * as FileSaver from 'file-saver';
import { DireitoService } from 'src/app/core/services/direito.service';


@Component({
  selector: 'app-report-atendimento',
  templateUrl: './report-atendimento.component.html',
  styleUrls: ['./report-atendimento.component.scss']
})
export class ReportAtendimentoComponent {
  dropdownUsuarios: any[] = [];

  dropdownStatus: any[] = [
    { value: '0', label: 'Aberto' },
    { value: '1', label: 'Em andamento' },
    { value: '2', label: 'Finalizado' },
  ];

  dropdownAvaliacao: any[] = [
    { value: 'high', label: 'Da melhor para a pior' },
    { value: 'low', label: 'Da pior para a melhor' },
  ];

  configuracao: any
  avalaciacaoSelecionada: any

  avaliacao: boolean = false;

  selectedUsuario: any[] = [];
  selectedVendedor: any[] = [];
  selectedStatus: any[] = [];

  cols: any[] = [
    { field: 'id', header: 'Código' },
    { field: 'cliente', header: 'Cliente' },
    { field: 'whatsapp_contato', header: 'Whatsapp contato' },
    { field: 'atendente', header: 'Atendente' },
    { field: 'situacao', header: 'Situação' },
    { field: 'avaliacao_atendimento', header: 'Avaliação atendimento' },
    { field: 'data_hora_aberto', header: 'Data da abertura' },
    { field: 'data_hora_andamento', header: 'Data do atendimento' },
    { field: 'data_hora_finalizado', header: 'Data finalização' },
    { field: 'avaliacao_atendimento', header: 'Avaliação' },
  ];

  colsRank: any[] = [
    { field: 'usuario', header: 'Atendente' },
    { field: 'count', header: 'Atendimentos' },
    { field: 'min', header: 'Menor avaliação' },
    { field: 'max', header: 'Maior avaliação' },
    { field: 'average', header: 'Média' },
  ];

  rank: boolean = false

  rangeDates: any[] = [];

  rangeDatesRank: any[] = [];

  data: any[] = [];
  dataRank: any[] = [];
  dataExcel: any[] = [];
  dataExcelRank: any[] = [];

  virtualScrollItemSize: number = 40;

  loading: boolean = false;

  pt: any = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };


  constructor(
    private usuarioService: UsuarioService,
    private chatService: ChatService,
    private config: PrimeNGConfig,
    public direitoService:DireitoService
  ) { } 

  ngOnInit(): void {
    this.configuracao = JSON.parse(localStorage.getItem('configuracao'));

    this.config.setTranslation({
      dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
        'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    });

    this.rangeDates = null;
    this.rangeDatesRank = null;
    this.listUsuarios();

    this.selectedStatus = [...this.selectedStatus, '0'];
    this.selectedStatus = [...this.selectedStatus, '1'];
    this.selectedStatus = [...this.selectedStatus, '2'];
  }

  listUsuarios() {
    this.usuarioService.index().subscribe(json => {
      json.forEach(element => {
        this.dropdownUsuarios.push({ value: element.id, label: element.name });
        this.selectedUsuario = [...this.selectedUsuario, element.id];
      });
    })

    if (this.configuracao.enviar_avaliacao_atendimento == true) {
      this.avaliacao = true
    } else {
      this.avaliacao = false
    }
  }

  report() {

    let startDate;
    let endDate;

    try {
      startDate = new Date(this.rangeDates[0]).getTime();
    } catch (error) {
      startDate = null;
    }

    try {
      endDate = new Date(this.rangeDates[1]).getTime();
    } catch (error) {
      endDate = null;
    }


    let body = {
      usuarios: this.selectedUsuario,
      situacao: this.selectedStatus,
      startDate: startDate,
      endDate: endDate
    }

    this.loading = true;
    this.chatService.reportAtendimentoView(body).subscribe(json => {
      this.loading = false;
      
      this.data = json;
      this.data.forEach(element => {
        if (element.situacao == '0') {
          element.situacao = 'Aberto';
        } else if (element.situacao == '1') {
          element.situacao = 'Em andamento';
        } else if (element.situacao == '2') {
          element.situacao = 'Finalizado';
        }

        if (element.avaliacao_atendimento == null) {
          element.avaliacao_atendimento = 'Não avaliado'
        } else {
          let estrelas = []
          for (let index = 0; index < element.avaliacao_atendimento; index++) {
            estrelas.push(index)

          }
          element.estrelas = estrelas
        }

        if (element.data_hora_aberto !== null) {
          var dataUltimaMensagemUTC = moment.utc(element?.data_hora_aberto).toDate();
          var dataUltimaMensagemLocal = moment(dataUltimaMensagemUTC).local().format('HH:mm (DD/MM/YYYY)');
          element.data_hora_aberto = dataUltimaMensagemLocal;
        }

        if (element.data_hora_andamento !== null) {
          var dataUltimaMensagemUTC = moment.utc(element?.data_hora_andamento).toDate();
          var dataUltimaMensagemLocal = moment(dataUltimaMensagemUTC).local().format('HH:mm (DD/MM/YYYY)');
          element.data_hora_andamento = dataUltimaMensagemLocal;
        }

        if (element.data_hora_finalizado !== null) {
          var dataUltimaMensagemUTC = moment.utc(element?.data_hora_finalizado).toDate();
          var dataUltimaMensagemLocal = moment(dataUltimaMensagemUTC).local().format('HH:mm (DD/MM/YYYY)');
          element.data_hora_finalizado = dataUltimaMensagemLocal;
        } 
      })
    }, err => {
      this.loading = false;
    });
  }

  exportExcel() {
    import("xlsx").then(xlsx => {

      this.dataExcel = [];

      this.data.forEach((dt, x) => {

        let obj = {};

        this.cols.forEach((i) => {

          

          obj[i.header] = `${dt[i.field]}`;

        })

        this.dataExcel.push(obj);

      });

      const worksheet = xlsx.utils.json_to_sheet(this.dataExcel);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "atendimentos");
    });
  }

  exportExcelRank() {
    import("xlsx").then(xlsx => {

      this.dataExcelRank = [];

      this.dataRank.forEach((dt, x) => {

        let obj = {};

        this.colsRank.forEach((i) => {

          

          obj[i.header] = `${dt[i.field]}`;

        })

        this.dataExcelRank.push(obj);

      });

      const worksheet = xlsx.utils.json_to_sheet(this.dataExcelRank);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "rank_atendimentos");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
