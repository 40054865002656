<p-table #dt1 [value]="dataSource" styleClass="p-datatable-sm p-datatable-gridlines" [scrollable]="true"
    [scrollHeight]="'calc(100vh - 250px)'" responsiveLayout="scroll" scrolldirection="both" [autoLayout]="true"
    [globalFilterFields]="['campo', 'departamento', 'id']" [rowHover]="true">

    <ng-template pTemplate="caption">
        <p-toolbar>
            <div class="p-toolbar-group-left">
                <div class="p-input-icon-left p-fluid p-field p-mr-2">
                    <input pInputText type="text" class="p-inputtext-sm ml-4"
                        (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar..." />
                </div>


            </div>

            <div class="p-toolbar-group-right d-flex">

                <button id="btn-export-departamento" type="button" class="btn btn-success btn mr-2"
                    style="margin-right: 5px;" (click)="exportExcel()" ngbTooltip="Exportar para excel"><i
                        class="pi pi-file-export"></i></button>

                <button *ngIf="direitoService.can('INSERIR_CAMPO_PERSONALIZADO')" type="button" class="btn btn-primary btn mr-2" (click)="showModal()" ngbTooltip="Adicionar"><i
                        class="pi pi-plus"></i></button>
            </div>
        </p-toolbar>
    </ng-template>


    <ng-template pTemplate="header">
        <tr c>
            <th>Código</th>
            <th>Nome</th>
            <!-- <th>Tipo</th> -->
            <th>Departamento</th>
            <th>Ativo</th>
            <th style="max-width:100px;"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr class="row-test">
            <td>{{item.id}}</td>
            <td>{{item.campo}}</td>
            <!-- <td>{{item.tipo}}</td> -->
            <td>{{item.departamento}}</td>
            <td>{{item.ativo ? 'Sim' : 'Não'}}</td>
            <td style="max-width:100px;">
                <button *ngIf="direitoService.can('ALTERAR_CAMPO_PERSONALIZADO')" type="button" class="btn btn-primary btn-sm mr-2" (click)="showModal(item.id)"
                    ngbTooltip="Editar"><i class="pi pi-pencil"></i></button>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="footer" let-columns>
        <tr>
            <td>
                Total de {{dataSource ? dataSource.length : 0 }} registros.
            </td>
        </tr>
    </ng-template>
</p-table>


<p-dialog header="Campos" [(visible)]="modal" [style]="{width: '50vw'}">
    <form [formGroup]="form" class="row g-2 p-3">

        <div class="col-md-12 mb-12" style="padding-bottom: 30px;">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="ativo">
                <label class="form-check-label" for="customCheck2">Ativo</label>
            </div>
        </div>

        <div class="col-md-12 mb-12" style="padding-bottom: 30px;">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="editavel">
                <label class="form-check-label" for="customCheck2">Editável</label>
            </div>
        </div>

        <div class="col-md-12 mb-12" style="padding-bottom: 30px;">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="filtro_rapido">
                <label class="form-check-label" for="customCheck2">Filtro rápido</label>
            </div>
        </div>

        <div class="col-sm-4">
            <label for="lastname2">Nome</label>
            <input pInputText class="form-control p-inputtext-sm" formControlName="campo" />
            <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
        </div>
        

        <div class="col-sm-4 p-fluid">
            <label for="lastname2">Tipo</label>
            <p-dropdown placeholder="Selecione" appendTo="body" [options]="dropdownTipo" class="p-inputtext-sm" formControlName="tipo">
            </p-dropdown>
        </div>

        <div class="col-sm-4 p-fluid">
            <label for="lastname2">Departamento</label>
            
            <p-multiSelect appendTo="body" [options]="dropdownDepartamento" formControlName="departamento_id" optionLabel="label"
            class="p-inputtext-sm">
          </p-multiSelect>
        </div>

    </form>

    <p-table *ngIf="form.get('tipo').value == 'DROPDOWN'" [value]="opcoesCampo" >
        <ng-template pTemplate="caption">
            <form [formGroup]="formOpcao" class="row p-3">

                <div class="col-sm-4">
                    <label for="lastname2">Nome</label>
                    <input pInputText class="form-control p-inputtext-sm" formControlName="label" />
                    <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
                </div>

                <div class="col-sm-5">
                    <label for="lastname2">Valor</label>
                    <input pInputText class="form-control p-inputtext-sm" formControlName="value" />
                    <small class="badge badge-soft-danger me-1 rounded-pill">(Recomendação: Maíusculo, não usar espaços e nem caracteres especiais)</small>
                </div>

                <div class="col-sm-2 mt-4">
                    <button [disabled]="formOpcao.invalid" type="button" class="btn btn-primary btn mr-2" (click)="addOpcao()"
                    ngbTooltip="Adicionar"><i class="pi pi-plus"></i></button>
                </div>

              
            </form>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th>Nome</th>
                <th>Valor</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-opcaoCampo>
            <tr>
                <td>
                    <input pInputText class="form-control p-inputtext-sm" [(ngModel)]="opcaoCampo.label" />
                </td>

                <td>
                    <input pInputText class="form-control p-inputtext-sm" [(ngModel)]="opcaoCampo.value" />
                </td>
            </tr>
        </ng-template>
    </p-table>

    <ng-template pTemplate="footer">

        <button type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;" (click)="modal = false"
            ngbTooltip="Fechar"><i class="pi pi-times"></i></button>

        <button [disabled]="form.invalid" type="button" class="btn btn-success btn mr-2" (click)="save()" ngbTooltip="Salvar"><i
                class="pi pi-save"></i></button>
    </ng-template>
</p-dialog>