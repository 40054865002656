import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class IntegracaoWhatsappService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'integracao-whatsapp');
  }

  getStatus(token: any, identificador: any): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/status/${token}/${identificador}`);
  }

  setStatus(body: any): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/set/status`, body);
  }

  disconnect(id: any): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/disconnect/${id}`);
  }

  qrcode(id: any): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/qrcode/${id}`);
  }

  connect(id: any): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/connect/${id}`);
  }

  recreate(body: any): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/recreate`, body);
  }

  findInstacia(id: any): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/instance/${id}`);
  }
}
