import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class DireitoService extends GenericService {
  direitos = JSON.parse(localStorage.getItem('direito')) || [];

  constructor(http: HttpClient) {
    super(http, 'direito');
  }

  getByDescricao(descricao: string): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/by/descricao/${descricao}`);
  }

  listByUsuario(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/by/usuario/${id}`);
  }

  getArvore(request = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/get/arvore`;
    if (request != null) {
      url += request;
    }
    return this.http.get(url);
  }


  saveVinculo(params): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/link/usuario`, params);
  }

  can(flag) {
    let permission = this.direitos.find(item => item.flag == flag);
    if (permission != undefined) {
      return true;
    } else {
      return false;
    }
  }
}
