<script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.3/dist/umd/popper.min.js"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
<script src="https://use.fontawesome.com/releases/v5.15.3/js/all.js"></script>

<div class="card">

  <div *ngIf="tela_charts == true">
    <div *ngIf="load" class="text-center">
      <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
        fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    </div>
    <div *ngIf="!load">
      <div *ngFor="let funil of jsonData; let i = index">
        <div [attr.id]="funil.funil" class="funnel-chart" (click)="selectFunil(funil)">

        </div>
      </div>


    </div>
  </div>




  <div *ngIf="tela_charts == false" class="container">

    <div *ngIf="load" class="text-center">
      <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
        fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    </div>

    <div *ngIf="!load" class="row p-d-flex text-center">
      <button pButton pRipple type="button" icon="pi pi-arrow-left" class="p-button-rounded"
        (click)="backCharts()"></button>
      <h5>
        {{title_funil_selected}}
      </h5>
    </div>

    <div *ngIf="!load && tela_charts == false" class="kanban-board">

      <!--sütun başlangıç-->
      <div *ngFor=" let etapa of etapas" class="kanban-column">
        <div class="kanban-column-heading">
          {{etapa.nome}}  ({{etapa.count}})
        </div>
        <div class="panel-body">
          <div class="kanban-centered">
            <article *ngFor="let negociacao of etapa.negociacoes" class="kanban-entry " id="item1">
              <div class="kanban-entry-inner" draggable="true" pDraggable="negociacao"
                (onDragStart)="dragStart(negociacao, etapa)" (onDragEnd)="dragEnd()" pDroppable="negociacao"
                (onDrop)="drop(etapa)">
                <div class="kanban-label" style="    background-color: white;">

                  <div class="d-flex align-items-center">
                    <div class="chat-avatar">
                      <div class="avatar-xs">
                        <span *ngIf="negociacao.id != null"
                          class="avatar-title rounded-circle bg-soft-primary text-primary">
                          {{(negociacao?.cliente?.nome | translate).charAt(0)}}
                        </span>
                      </div>
                    </div>

                    <div *ngIf="negociacao.id != null" class="ml-2"
                      style="white-space: nowrap; text-overflow: ellipsis;">
                      <h6><a href="#">{{negociacao?.cliente?.nome}}</a></h6>
                    </div>
                  </div>

                  <small *ngIf="negociacao.id != null">
                    <b>Whatsapp:</b>{{negociacao.cliente?.celular_principal | celular}}</small><br />

                  <small *ngIf="negociacao.id != null"> <b>Iniciado:</b> {{negociacao.created_at | date:'dd/MM/yyyy'}}
                  </small>

                  <!-- <div *ngIf="negociacao.id != null" class="col-12 text-start">
                    <p-badge *ngIf="negociacao.chat.usuario_atendimento_atual_id == null" severity="warning"
                      [value]="getStatusChat(negociacao.chat)" size="small"></p-badge>
                    <p-badge *ngIf="negociacao.chat.situacao == 2" severity="danger"
                      [value]="getStatusChat(negociacao.chat)" size="small"></p-badge>
                    <p-badge
                      *ngIf="negociacao.chat.usuario_atendimento_atual_id != null && negociacao.chat.situacao != 2"
                      [value]="getStatusChat(negociacao.chat)" size="small"></p-badge>
                  </div> -->

                  <hr>

                  <!-- <div *ngIf="negociacao.id != null" class="mt-4">
                    <div class="d-flex  justify-content-start">
                      <div style="cursor: pointer;">
                        <i class="pi pi-user-edit" style="font-size: 15px; margin-right: 10px;"></i>
                      </div>

                      <div>
                        <i class="pi pi-bell mr-4 p-text-secondary" pBadge style="font-size: 15px; margin-right: 10px;"
                          value="2"></i>

                      </div>

                      <div>
                        <i class="pi pi-file mr-4 p-text-secondary" pBadge style="font-size: 15px; margin-right: 10px;"
                          [value]="'10+'" severity="danger"></i>

                      </div>
                    </div>
                  </div> -->

                </div>
              </div>
            </article>



          </div>
        </div>
        <div class="panel-footer">
        </div>
      </div>


    </div>
  </div>
</div>
