<p-toolbar>
  <div class="p-toolbar-group-left">
    <div class="p-fluid row g-2 p-3">




      <div class="col-auto">
        <label for="lastname2">Período</label>
        <p-calendar class="p-inputtext-sm" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="rangeDates" [locale]="pt"
          selectionMode="range" [readonlyInput]="false" inputId="range">
        </p-calendar>
      </div>

      <div class="col-auto">
        <label for="lastname2">Status</label>
        <p-dropdown appendTo="body" placeholder="Selecione" [options]="dropDownOrdem" class="p-inputtext-sm"
          [(ngModel)]="ordem">
        </p-dropdown>
      </div>

      <div class="col-auto">
        <p-button icon="ri-search-line" styleClass="p-button-info mt-3" (click)="report()">
        </p-button>
      </div>

      <div class="col-auto">
        <p-button icon="ri-file-excel-line" styleClass="p-button-success mt-3" (click)="exportExcel()">
        </p-button>
      </div>
    </div>
  </div>
</p-toolbar>

<div class="flex-fill overflow-hidden">
  <p-table #dt1 [paginator]="true" [rows]="10" [showCurrentPageReport]="true" responsiveLayout="stack" [value]="data"
    [rowHover]="true" currentPageReportTemplate="{first} de {totalRecords} " [loading]="loading"
    styleClass="p-datatable p-datatable-gridlines">

    <ng-template pTemplate="header">
      <tr>
        <th>Atendente</th>
        <th>Menor nota</th>
        <th>Maior nota</th>
        <th>Média</th>
        <th>Total de atendimentos</th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td>{{item.usuario}}</td>
        <td>{{item.min}}</td>
        <td>{{item.max}}</td>
        <td>{{item.average}}</td>
        <td>{{item.count}}</td>

      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr *ngIf="data.length>0">
        <td colspan="9">{{data.length}} registros encontrados</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td>Nenhum dado encontrado.</td>
      </tr>
    </ng-template>
  </p-table>
</div>
