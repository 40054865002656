import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../services/auth.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private router: Router, private messageService: MessageService,) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            
            
            const error = err.error.message || err.statusText;

            

            if (err.status === 401) {
                this.messageService.add({ severity: 'err', summary: 'Aviso', detail: `Token expirado, entre no sistema novamente!` });
                this.router.navigate(['/account/login']);
            } else if (err.status == 422) {
                this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: `${error}` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erro', detail: `${error}` });

            }


            return throwError(error);
        }));
    }
}
