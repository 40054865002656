import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { ToolbarModule } from 'primeng/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardAtendimentosComponent } from './dashboard-atendimentos/dashboard-atendimentos.component';
import { ChartLineComponent } from './chart-line/chart-line.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AgendaModule } from '../agenda/agenda.module';



@NgModule({
  declarations: [DashboardPageComponent, DashboardAtendimentosComponent, ChartLineComponent],
  imports: [
    CommonModule,
    DropdownModule,
    ButtonModule,
    TableModule,
    TabViewModule,
    MultiSelectModule,
    CalendarModule,
    ToolbarModule,
    FormsModule,
    ReactiveFormsModule, 
    ProgressSpinnerModule,
    PerfectScrollbarModule,
    AgendaModule
  ],
  exports: [DashboardPageComponent, ChartLineComponent]
})
export class DashboardModule { }
