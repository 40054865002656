<div class="card" style="height: calc(100vh - 100px);">

  <perfect-scrollbar class="p-0 chat-message-list chat-group-list" data-simplebar>
    <div class="p-4 text-center">
      <p-progressSpinner *ngIf="loading" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner"
        strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    </div>

    <p-toolbar *ngIf="!loading && form.disabled">
      <div class="p-toolbar-group-left">
        <button type="button" class="btn btn-success btn mr-2" style="margin-right: 5px;" (click)="showModalNewChat()"
          ngbTooltip="Iniciar um novo atendimento"><i class="ri-message-line"></i></button>

        <button *ngIf="form.disabled" type="button" class="btn btn-info btn mr-2" style="margin-right: 5px;"
          (click)="form.enable()" ngbTooltip="Editar contato"><i class="ri-edit-line"></i></button>
      </div>
    </p-toolbar>

    <p-toolbar *ngIf="!form.disabled">
      <div class="p-toolbar-group-left">
        <button *ngIf="!form.disabled" type="button" class="btn btn-danger btn mr-2" style="margin-right: 5px;"
          (click)="cancel()" ngbTooltip="Cancelar edição"><i class="ri-close-circle-line"></i></button>

        <button *ngIf="!form.disabled" type="button" class="btn btn-info btn mr-2" style="margin-right: 5px;"
          (click)="save()" ngbTooltip="Salvar contato"><i class="ri-save-line"></i></button>
      </div>
    </p-toolbar>

    <!-- <form  [formGroup]="form" class="mt-4" *ngIf="!loading">

            <div class="col-md-3 mb-12" style="padding-bottom: 30px;" formGroupName="pessoa">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="ativo">
                    <label class="form-check-label" for="customCheck2">Ativo</label>
                </div>
            </div>

            <div class="col-md-3 mb-12" style="padding-bottom: 30px;" formGroupName="pessoa">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="customCheck2"
                        formControlName="permitir_chatbot">
                    <label class="form-check-label" for="customCheck2">Permitir interação do chatbot</label>
                </div>
            </div>

            <div class="mb-3" formGroupName="pessoa">
                <label for="inputtext">Nome</label>
                <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText
                    formControlName="nome">
                <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
            </div>

            <div class="mb-3 p-fluid" formGroupName="pessoa">
                <label for="inputtext">Celular/Whatsapp</label>
                <p-inputMask mask="+99 (99) 99999-9999" formControlName="celular_principal"
                    class="p-inputtext-sm inputmask">
                </p-inputMask>
                <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
            </div>

            <div *ngIf="form.get('pessoa.tipo')?.value == 'FISICA'" class=" mb-3" formGroupName="fisica">
                <label for="inputtext">Apelido</label>
                <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText
                    formControlName="apelido">
            </div>

            <div class="col-md-12 mb-3 p-fluid" formGroupName="tag">
                <label>Tags</label>
                <p-multiSelect [options]="dropDownTag" formControlName="tags" optionLabel="label"
                    class="p-inputtext-sm">
                </p-multiSelect>
            </div>


            <div *ngIf="form.get('pessoa.tipo')?.value == 'FISICA'" formGroupName="fisica" class="mb-3 p-fluid">
                <label for="inputtext">Nascimento</label>
                <p-calendar dateFormat="dd/mm/yy" [locale]="pt" formControlName="nascimento" class="p-inputtext-sm">
                </p-calendar>
            </div>


        </form> -->

    <p-tabView>
      <p-tabPanel header="Dados principais">
        <form [formGroup]="form" class="row">
          <div class="col-md-3 mb-12" style="padding-bottom: 30px;" formGroupName="pessoa">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="customCheck2" formControlName="ativo">
              <label class="form-check-label" for="customCheck2">Ativo</label>
            </div>
          </div>

          <div class="mb-3" formGroupName="pessoa">
            <label for="inputtext">Tipo</label>
            <p-dropdown class="p-inputtext-sm" [options]="dropdownTipo" formControlName="tipo"></p-dropdown>
            <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
          </div>

          <div class="mb-3" formGroupName="pessoa">
            <label for="inputtext">Nome</label>
            <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText formControlName="nome">
            <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
          </div>

          <div *ngIf="form.get('pessoa.tipo').value == 'JURIDICA'" class="mb-3" formGroupName="juridica">
            <label for="inputtext">Razão Social</label>
            <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText
              formControlName="razao_social">
          </div>

          <div *ngIf="form.get('pessoa.tipo').value == 'JURIDICA'" class="mb-3 p-fluid" formGroupName="juridica">
            <label for="inputtext">CNPJ</label>
            <p-inputMask mask="99.999.999/9999-99" formControlName="cnpj" class="p-inputtext-sm">
            </p-inputMask>
          </div>

          <div *ngIf="form.get('pessoa.tipo')?.value == 'FISICA'" class=" mb-3" formGroupName="fisica">
            <label for="inputtext">Apelido</label>
            <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText formControlName="apelido">
          </div>

          <div *ngIf="form.get('pessoa.tipo').value == 'FISICA'" class="p-fluid mb-3" formGroupName="fisica">
            <label for="inputtext">CPF</label>
            <p-inputMask mask="999.999.999-99" formControlName="cpf" class="p-inputtext-sm">
            </p-inputMask>
          </div>

          <div class="mb-3 p-fluid" formGroupName="pessoa">
            <label for="inputtext">Celular/Whatsapp</label>
            <p-inputMask mask="+99 (99) 99999-9999" formControlName="celular_principal" class="p-inputtext-sm">
            </p-inputMask>
            <small class="badge badge-soft-danger me-1 rounded-pill">Obrigatório</small>
          </div>

          <div class="col-md-12 mb-3 p-fluid" formGroupName="tag">
            <label>Tags</label>
            <p-multiSelect [options]="dropDownTag" formControlName="tags" optionLabel="label">
            </p-multiSelect>
          </div>

          <div *ngIf="form.get('pessoa.tipo')?.value == 'FISICA'" formGroupName="fisica" class="mb-3 p-fluid">
            <label for="inputtext">Nascimento</label>
            <p-calendar dateFormat="dd/mm/yy" [locale]="pt" class="p-inputtext-sm" formControlName="nascimento">
            </p-calendar>
          </div>
        </form>
      </p-tabPanel>
      <p-tabPanel header="Endereço">
        <form [formGroup]="form" class="row">

          <div class="mb-3 col-md-10" formGroupName="endereco">
            <label for="inputtext">CEP</label>
            <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText formControlName="cep">
          </div>

          <div class=" mb-3 col-md-2" formGroupName="endereco" style="margin-top: 20px;">
            <p-button icon="pi pi-search" styleClass="p-button-sm" (click)="buscaCEP()"></p-button>
          </div>

          <div class=" mb-3 col-md-10" formGroupName="endereco">
            <label for="inputtext">Endereço</label>
            <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText
              formControlName="logradouro">
          </div>

          <div class=" mb-3 col-md-2" formGroupName="endereco">
            <label for="inputtext">Numero</label>
            <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText formControlName="numero">
          </div>

          <div class=" mb-3" formGroupName="endereco">
            <label for="inputtext">Complemento</label>
            <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText
              formControlName="complemento">
          </div>

          <div class=" mb-3" formGroupName="endereco">
            <label for="inputtext">Bairro</label>
            <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText formControlName="bairro">
          </div>

          <div class=" mb-3 col-md-10" formGroupName="endereco">
            <label for="inputtext">Municipio</label>
            <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText
              formControlName="municipio">
          </div>

          <div class=" mb-3 col-md-2" formGroupName="endereco">
            <label for="inputtext">UF</label>
            <input id="firstname2" type="text" class="form-control p-inputtext-sm" pInputText formControlName="uf">
          </div>






        </form>
      </p-tabPanel>
    </p-tabView>


  </perfect-scrollbar>


</div>

<p-dialog header="Nova conversa" [(visible)]="modalNewChat" [style]="{width: '50vw'}">
  <form [formGroup]="formAtendimento">
    <div class="col-sm-12">
      <label for="lastname2">Canal de atendimento</label>
      <p-dropdown appendTo="body" placeholder="Selecione um canal" [options]="dropdownCanalAtendimento"
        formControlName="canal_atendimento_id"></p-dropdown>
    </div>

    <div class="col-sm-12">
      <label for="lastname2">Resposta rapida</label>
      <input pInputText class="form-control" formControlName="resposta_rapida" (input)="onBlur()" />
    </div>

    <div class="col resposta-rapida" *ngIf="respostaRapida">
      <div class="p-fluid row col-12 pt-4">
        <div class="col-2" (click)="respostaRapida = false">
          <span class="btn btn-sm btn-danger float-right cancel_reply_msg"><i class="pi pi-times"></i></span>
        </div>
      </div>

      <ul *ngFor="let resposta of fastResponseList" class="item-response-fast">

        <div class="row col-10 d-block mt-3" style="cursor: pointer;" (click)="copiaRespostaRapida(resposta)">
          <article>
            <h4 style="font-weight: bolder;">{{resposta.atalho}}</h4>
            <p>{{resposta.texto}}</p>
          </article>
        </div>

      </ul>
    </div><!-- end col-->

    <div class="col-sm-12">
      <label for="lastname2">Gatilhos</label>
      <p-dropdown appendTo="body" placeholder="Selecione um canal" [options]="dropdownGatilho"
        formControlName="gatilho_id"></p-dropdown>
    </div>
  </form>


  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="newChat()" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>