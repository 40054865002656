import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public translate: TranslateService) {
    translate.addLangs(['en', 'es', 'it', 'ru', 'de']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|it|es|ru|de/) ? browserLang : 'en');
  }

  ngOnInit(): void {
    const temaLink = document.createElement('link');
    temaLink.id = 'tema-stylesheet-primeng';
    temaLink.rel = 'stylesheet';
    document.head.appendChild(temaLink);


  }

  ngAfterViewInit(): void {
    let element = document.getElementById('btn-export-departamento');
    
  }
}
