import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DireitoService } from 'src/app/core/services/direito.service';
import { GrupoUsuarioService } from 'src/app/core/services/grupo-usuario.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'app-access-permissions',
  templateUrl: './access-permissions.component.html',
  styleUrls: ['./access-permissions.component.scss']
})
export class AccessPermissionsComponent {
  selectedDireitos = [];
  form: FormGroup;
  direitoInserir: any[] = [];
  dropDownUsuarios = [];
  direitos = [];
  cols: any[] = []
  novosDireitos = [];
  filterType = 'user';
  dropdownGrupos = [];
  loading = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _usuarioService: UsuarioService,
    private _direito: DireitoService,
    private _grupoUsuarioService: GrupoUsuarioService,
  ) { }

  ngOnInit(): void {
    let direitos = JSON.parse(localStorage.getItem('direito'));
    this.direitoInserir = direitos.find(item => item.flag == 'DIREITO_USUARIO_INSERIR')

    this.form = this._formBuilder.group({
      usuario: this._formBuilder.group({
        id: [null, Validators.required],
      }),
      grupo: this._formBuilder.group({
        id: [null, Validators.required],
      }),
    });


    this.listUsers();

    this.tree();
  }

  changeType() {
    if (this.filterType == 'user') {
      this.listUsers();
    } else {
      this.listGroups();
    }
  }

  listUsers() {
    this.dropDownUsuarios = [];
    this._usuarioService.index().subscribe(json => {
      json.map((item: any) => {
        this.dropDownUsuarios.push({ value: item.id, label: item.email, grupo_usuario_id: item.grupo_usuario_id });
      })
    })
  }

  listGroups() {
    this.dropdownGrupos = [];
    this._grupoUsuarioService.index().subscribe(groups => {
      groups.forEach(group => {
        this.dropdownGrupos.push({ value: group.id, label: group.nome })
      });

    })
  }

  selectedDireito() {
    this.novosDireitos = [];
    this.selectedDireitos.map(item => {
      this.novosDireitos.push({
        id: item.data.direito_id
      });

    })
  }

  selecionaUsuario() {

    this.direitos = []
    this.selectedDireitos = [];


    this._direito.listByUsuario(this.form.get('usuario.id').value).subscribe(json => {
      json.map(item => {

        let data = {
          label: item.descricao, direito_id: item.direito_id
        }

        this.selectedDireitos.push({
          data: data,
        });

        this.selectedDireito();


      });

      this.tree();

    })
  }

  selecionaGrupo() {
    this.direitos = []
    this.selectedDireitos = [];
    this.tree();
  }


  tree() {
    this._direito.getArvore().subscribe(json => {
      this.direitos = json.filter(tree => {
        return (tree.flag != 'APP_DESCONTO_LOGISTA')
      });

      this.cols = [
        { field: 'label', header: 'Descrição' },
      ];
    })

  }

  saveVinculo() {
    this.loading = true;

    if (this.filterType == 'user') {
      let params = {
        direitos: this.novosDireitos,
        usuario_id: this.form.get('usuario.id').value,
      }
  
      this._direito.saveVinculo(params).subscribe(json => {        
        this._direito.listByUsuario(this.form.get('usuario.id').value).subscribe(json => {
          this.loading = false;
          localStorage.setItem('direito', JSON.stringify(json));
        })
      })
    } else {
      let userLogado = JSON.parse(localStorage.getItem('user'));
      let usersByGroup = this.dropDownUsuarios.filter(users => {return users.grupo_usuario_id == this.form.get('grupo.id').value});
      usersByGroup.forEach(user => {
        let params = {
          direitos: this.novosDireitos,
          usuario_id: user.value,
        }
        this._direito.saveVinculo(params).subscribe(json => {
          if (userLogado.id == user.value) {
            this._direito.listByUsuario(userLogado.id).subscribe(json => {
              localStorage.setItem('direito', JSON.stringify(json));                
            })
          }
        })
      });

    }

  }
}
