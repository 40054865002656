import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DepartamentoService } from 'src/app/core/services/departamento.service';
import { RespostaRapidaService } from 'src/app/core/services/resposta-rapida.service';

@Component({
  selector: 'app-resposta-rapida',
  templateUrl: './resposta-rapida.component.html',
  styleUrls: ['./resposta-rapida.component.scss']
})
export class RespostaRapidaComponent {
  direitoInserir: any[] = [];
  form!: FormGroup;
  dropdownDepartamento: any[] = [];
  respostas: any[] = [
    // { atalho: 'TESTE', mensagem: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using' }
  ];

  dicionario: any[] = [
    { dica: '{DAY_GREETING}', descricao: 'Bom dia, Boa tarde, Boa noite' },
    { dica: '{PRIMEIRO_NOME_LEAD}', descricao: 'Primeiro nome do cliente' },
  ];

  constructor(
    private _formBuilder: FormBuilder,
    private _resposta: RespostaRapidaService,
    private departamentoService: DepartamentoService,
  ) { }

  ngOnInit(): void {
    let direitos = JSON.parse(localStorage.getItem('direito'));
    this.direitoInserir = direitos.find(item => item.flag == 'RESPOSTA_INSERIR')

    this.form = this._formBuilder.group({
      atalho: [null],
      texto: [null],
      departamento_id: [null],
    })
    
    this.listDepartamentos()
    this.index()
    
    
  }

  index(){
    this.respostas = []
    this._resposta.index().subscribe(json => {
      json.map(item => {
        this.respostas.push({
          id: item.id,
          atalho: item.atalho,
          texto: item.texto,
          departamento_id: item.departamento_id,
        })
      })
    })
  }

  listDepartamentos() {
    this.dropdownDepartamento.push({
      value: 'null', label: 'Selecione um departamento'
    })
    this.departamentoService.index().subscribe(json => {
    json.map(item =>  this.dropdownDepartamento.push(
        { value: item.id, label: item.nome }
      ) 
    )})
  }

  delete(event) {
    this._resposta.delete(event).subscribe(json => {
      this.respostas = []
      this._resposta.index().subscribe(json => {
        json.map(item => {
          this.respostas.push({
            id: item.id,
            atalho: item.atalho,
            texto: item.texto,
            departamento_id: item.departamento_id,
          })
        })
      })
    })
  }

  save() {
    let x = this.form.value
    if (x.id == null) {
      this._resposta.save(x).subscribe(json => {
        this.form.reset()
        this.index()
       
      })
    }

  }

}
