<div *ngIf="load" class="text-center">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
        fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
</div>

<div *ngIf="chatSelected != null && dropdownDepartamentos.length > 0 && direitoService.can('ALTERAR_ATENDENTE_CHAT')"
    class="p-fluid mt-4 row">

    <div class="col-sm-12">
        <label>Departamento</label>
        <p-dropdown class="p-inputtext-sm" filter="true" [options]="dropdownDepartamentos"
            [(ngModel)]="chatSelected.departamento_id" (onChange)="updateDepartamentoChat()"></p-dropdown>
    </div>

    <div class="col-sm-12">
        <label for="lastname2">Responsável</label>

        <p-progressSpinner *ngIf="loadingUsers" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner"
            strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
        
            <p-dropdown *ngIf="!loadingUsers" class="p-inputtext-sm" filter="true" [options]="dropdownUsers"
            [(ngModel)]="chatSelected.usuario_atendimento_atual_id" (onChange)="updateUsuario()"></p-dropdown>
    </div>


</div>

<div class="p-fluid mt-4">

    <p-panel>
        <ng-template pTemplate="header">
            <span style="margin-right: 5px;">Tags: </span>
            <p-dropdown class="p-inputtext-sm ml-2" [(ngModel)]="tag" filter="true" [options]="dropdownTags"
                placeholder="Adicionar tag" optionLabel="descricao" (onChange)="selectTag()"></p-dropdown>
        </ng-template>


        <div class="row d-flex" style="padding: 12px;">
            <div *ngFor="let item of tagsSelected; let i = index" class="col-auto chat_tag_item rounded mr-2 mb-2"
                style="color:#007bff; background:#ffffff;margin-right: 5px;">

                <div class="pointer delete_chat_tag" data-chat-id="6408e91f7bc8dd798c230d35"
                    data-tag-text=" {{item.descricao}}" id="tagDiv" (mouseout)="mostrarIcone = false">
                    {{item.descricao}}<i style="    font-size: 12px;
                    border: 1px solid;
                    border-radius: 8px;
                    margin-left: 6px;
                    padding: 3px; cursor: pointer; color: red;" class="pi pi-times" (click)="removeTag(item)"></i>
                </div>
            </div>
        </div>
    </p-panel>



    <!-- <p class="mt-4">

        <span *ngFor="let item of tagsSelected" class="badge badge-soft-info rounded-pill mr-2"
            style="margin-right: 5px;">{{item.descricao}} <span class="badge badge-soft-primary rounded-pill"><i
                    class="pi pi-times" style="font-size: 10px; cursor: pointer;"
                    (click)="removeTag(item)"></i></span></span>
    </p> -->
</div>