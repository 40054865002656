import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PessoaService } from 'src/app/core/services/pessoa.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'app-compartilhar-mensagem',
  templateUrl: './compartilhar-mensagem.component.html',
  styleUrls: ['./compartilhar-mensagem.component.scss']
})
export class CompartilharMensagemComponent {
  @Input() modal = false;

  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onShared: EventEmitter<any> = new EventEmitter();


  selectedContact;

  filteredPessoas;

  showEmptyMessage = true;

  loading = false;

  contactList = [];

  sharedType = 0;

  userList = [];
  

  constructor(
    private pessoaService: PessoaService,
    private usuarioService: UsuarioService,
  ) {

  }

  ngOnInit(): void {
    this.listUsers();
  }
  
  filterPessoas(event) {
    this.loading = true;

    let query = event.query;

    let body = {
      nome: query
    }

    this.pessoaService.autoCompletePessoa(body).subscribe(json => {
      this.loading = false;
      this.filteredPessoas = json;
    })
  }

  listUsers() {
    this.usuarioService.grid().subscribe(json => {
      this.userList = json;
    })
  }

  addContact() {
    this.contactList.push(this.selectedContact);
    this.selectedContact = null;
  }

  remove(item) {
    let list = this.contactList.filter(c => {return c.id != item.id});
    this.contactList = list;
  }

  cancel() {
    this.onCancel.emit();
  }

  
  onRowSelect(event) {
    this.contactList = [this.selectedContact]
}

  shared() {
    let params = {
      contactList: this.contactList,
      sharedType: this.sharedType
    }
    this.onShared.emit(params);
  }

  onType(event) {
    this.sharedType = event.index;
  }
}
